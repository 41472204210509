import React, { useState } from 'react'
import { useDimensions } from "../DimensionContext/DimensionContext";
import SettingDrawer from '../Components/SettingDrawer';
import { Alert, Breadcrumb, Button, Skeleton, message } from 'antd';
import { EntPlanIcon, OverDueIcon } from '../Components/Icons';
import Header from '../Components/Header';
import { useTheme } from '../Themes/ThemeProvider';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { formatDateFunction, get, post } from '../Services/api-services';
import { useEffect } from 'react';
import { H1, H2, H3, H4, H5, H6, P } from '../Themes/StylingComponent';
import SubscriptionCard from '../Components/SubscriptionCard';
import { useNavigate } from 'react-router-dom'
import { Historyskaleton, SubscriptionSkaleton } from './DocumentSkaleton';

const Billing = () => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions()
    const navigate = useNavigate()
    const [allTransactions, setAllTransactions] = React.useState([])
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [allPlans, setAllPlans] = React.useState([])
    const [currentPlan, setCurrentPlan] = React.useState('')
    const [planStatus, setPlanStatus] = React.useState('')
    const [nextDue, setNextDue] = React.useState('');
    const [currency, setCurrency] = useState();
    const [credits, setCredits] = useState();
    const [allData, setAllData] = useState({})
    const [dataLoading, setDataLoading] = React.useState(true)
    const [historyLoading, setHistoryLoading] = React.useState(true)

    const checkCountryAndPlanInTeam = async (id) => {
        try {
            const res = await get(`team/${id}`);
            if (res.statusCode === 200) {
                // setCurrency(res?.data?.country?.currency)
                if (!res?.data?.country) {
                    navigate('/mobile-signup')
                } else if (!res?.data?.plan) {
                    navigate('/select-plan')
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
    // GET ALL TRANSECTIONS 

    useEffect(() => {
        checkCountryAndPlanInTeam(authState?.teamId)
        getAllTransections();
        getPlans();
    }, [])

    const getAllTransections = async () => {
        try {
            const response = await get(`team/${authState?.teamId}/transaction`)
            if (response?.statusCode == 200) {
                setAllTransactions(response?.data)
                setHistoryLoading(false)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING TRANSECTIONS ${e}`);
        }
    }

    const getPlans = async () => {
        try {
            const response = await get(`team/${authState?.teamId}/plan`)
            if (response?.statusCode == 200) {
                setAllData(response?.data)
                setAllPlans(response?.data?.plans)
                setCurrentPlan(response?.data?.planTeam)
                setCurrency(response?.data?.teamData?.currency);
                setCredits(response?.data?.teamBlance)
                // Convert the provided timestamp to a Date object
                const endValidityDate = new Date(response?.data?.planTeam?.endValidity);

                // Get the current date and time
                const currentDate = new Date();

                // Calculate the difference in milliseconds between the end validity date and current date
                const differenceInMilliseconds = endValidityDate - currentDate;

                let status;
                let nextDueDate;

                if (differenceInMilliseconds <= 0) {
                    status = 'Overdue'; // End validity date has passed or is the same as the current date
                    nextDueDate = null; // No next due date if overdue
                } else if (differenceInMilliseconds <= 2 * 24 * 60 * 60 * 1000) {
                    status = 'Due Now'; // End validity date is within 2 days from now
                    nextDueDate = currentDate; // Next due date is current date
                } else {
                    status = 'Due Next'; // End validity date is more than 2 days from now
                    // Calculate next due date by adding 2 days to the current date
                    nextDueDate = new Date(currentDate);
                    nextDueDate.setDate(currentDate.getDate() + 2);
                }

                // console.log(status); 
                // console.log(formatDateFunction(nextDueDate)); // Next due date

                setNextDue(nextDueDate)
                setPlanStatus(status)
                setDataLoading(false)
            }
        } catch (e) {
            console.log(`ERRRO WHILE FETCHING PLANS ${e}`);
        }
    }

    const handleCurrentPlanUpgrade = async (data) => {
        try {
            let plan;
            for (let index = 0; index < data?.plans.length; index++) {
                const element = data?.plans[index];
                if (element?._id === data?.currectPlanId) {
                    plan = element
                }
            }
            let isYearly = data?.currectPlanType === 'yearly' ? true : data?.currectPlanType === 'monthly' ? false : true
            let isBilling = true
            let body = { plan, currency, isYearly, isBilling }
            navigate('pay_now', { state: body }, { replace: true })
        } catch (e) {
            console.log(e)
        }
    }

    React.useState(() => {
        if (authState?.role == 'moderator') {
            window.location = '/settings/profile'
        }
    }, [authState])

    const contactUs = async () => {
        try {
            const response = await post(`team/${authState?.teamId}/user/${authState?.id}`, {})
            if (response.statusCode == 200) {
                message.success(response?.data?.message)
            } else {
                message.error(response.message) 
            }
        } catch (e) {
            console.log(`ERROR ${e}`);
        }
    }   

    return (
        <>
            <div className={`w-full h-full py-4 lg:px-6  ${themeColors.bg.primary}`}>
                <div className='px-3'>
                    <Header title='MY SUBSCRIPTION' />
                </div>
                {/* SUBSCRIPTION CARDS  */}
                <div className='w-full flex shrink-0 px-2 my-4 overflow-x-auto gap-4 items-center'>
                    {/* <img src={Plan1} alt="Plan1" /> */}
                    {dataLoading ? <SubscriptionSkaleton /> :
                        allPlans?.length > 0 ?
                            allPlans?.map((plan, index) => (
                                <SubscriptionCard
                                    key={index}
                                    plan={plan}
                                    currency={currency}
                                    credits={credits}
                                    allData={allData}
                                    isSetting={true}
                                />
                            ))
                            : null}

                    {dataLoading ? null :
                        <div className='w-full md:w-1/2 py-5 lg:w-[40%] xl:w-[20%] flex items-center justify-center  shrink-0 p-1'>
                            <div
                                className={`w-full h-full bg-[#484848] shadow-planCard rounded min-h-72  flex flex-col justify-between gap-4 p-6 `}
                            >
                                <div className='w-full items-center flex gap-2'>
                                    <EntPlanIcon
                                        color={themeColors?.icons?.primary}
                                    />
                                    <h2 className={`${themeColors?.headings?.third} tracking-wide font-normal text-lg lg:text-xl`}>Enterprise</h2>
                                </div>
                                <p className={`font-normal text-[#F7F7F7]  py-4 text-center  text-xs lg:text-xs `}>
                                    Our Enterprise Plan is tailored to meet the unique needs of your business. With customizable features and flexible pricing, we provide a solution that scales with your requirements. Contact us for a personalized quote and discover how Signizy can optimize your document workflows.
                                </p>
                                {/* <h6 className={`text-white text-center text-2xl opacity-0`}>/month</h6> */}
                                <div className='flex items-end justify-center'>
                                    <Button
                                        type='primary'
                                        size='large'
                                        style={{
                                            width: '100%',
                                            fontWeight: 700,
                                            borderRadius: 3
                                        }}
                                        onClick={() => contactUs()}
                                    >
                                        Contact Us
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }

                </div>

                {/* OVERDUE DIV */}
                <div className='px-3 '>
                    {/* <Header title='BILLING' /> */}
                    <H2 title='BILLING' />
                </div>

                {dataLoading ?
                    <div className='w-full py-4'>
                        <Skeleton.Button active={true} size={'large'} block={true} />
                    </div>
                    :
                    <div className='w-full  flex flex-col px-3 py-6 items-start gap-4'>
                        {/* <div className='w-full bg-[#FFF1F0] border border-[#FF0000] p-2 flex items-center justify-between'> */}
                        <div
                            className={`
                             w-full ${planStatus === 'Overdue' ? 'bg-[#FFF1F0]' : planStatus === 'Due Now' ? ' bg-[#FFFBE6]' : 'bg-[#E6F4FF]'}
                             border ${planStatus === 'Overdue' ? 'border-[#FF0000] ' : planStatus === 'Due Now' ? 'border-[#FFE58F]' : 'border-[#9DD0FF]'}  
                             p-2 flex items-center justify-between`
                            }
                        >   
                            <div className='flex items-start gap-2 '>
                                <div className='mt-1'>
                                    {/* <OverDueIcon /> */}
                                </div>
                                <div>
                                    <h6 className={`${planStatus === 'Overdue' ? 'text-[#FF0000]' : 'text-[#424D51]'} font-medium text-sm lg:text-base`}>{planStatus ? planStatus : null}</h6>
                                    <p className={`text-[#424D51] font-normal text-xs lg:text-xs`}>{formatDateFunction(currentPlan?.endValidity)}</p>
                                </div>
                            </div>
                            {planStatus != 'Due Next' ?
                                <div className='flex items-center gap-3 lg:gap-5'>
                                    {/* <h6 className='text-[#424D51] text-xl lg:text-2xl'>$12</h6> */}
                                    <Button
                                        style={{
                                            borderRadius: 0,
                                        }}
                                        onClick={() => {
                                            handleCurrentPlanUpgrade(allData)
                                        }}
                                        type="primary"
                                        danger={planStatus === 'Due Next' ? false : true}
                                    >
                                        Pay now
                                    </Button>
                                </div>
                                : planStatus === 'Due Next' ?
                                    <div className='flex items-center gap-3 lg:gap-5'>
                                        {/* <h6 className='text-[#424D51] text-xl lg:text-2xl'>$12</h6> */}
                                        <Button
                                            style={{
                                                borderRadius: 0,
                                            }}
                                            onClick={() => {
                                                handleCurrentPlanUpgrade(allData)
                                            }}
                                            type="primary"
                                            danger={planStatus === 'Due Next' ? false : true}
                                        >
                                            Extend validity
                                        </Button>
                                    </div>
                                    :
                                    null}
                        </div>
                    </div>
                }

                <div className='w-full px-3 py-4 '>
                    <div className={`w-full py-4 ${themeColors.borders.third}`}>
                        <h6 className={`text-base lg:text-lg ${themeColors.text.fourth}`}>History</h6>
                    </div>

                    <div className='w-full flex-1'>
                        {historyLoading ? (
                            <Historyskaleton />
                        ) : allTransactions?.length > 0 ? (
                            allTransactions.map((elm, idx) => (
                                <div key={idx} className='flex w-full items-center justify-between py-2'>
                                    <div className='w-fit '>
                                        <P  
                                            title={formatDateFunction(elm?.createdAt)}
                                        />
                                    </div>      
                                    <div className='flex w-1/2 lg:w-1/6 items-start justify-between  gap-4 lg:gap-10'>
                                        <p className={`text-xs  text-left capitalize  ${elm?.status == 'failed' ? 'text-red-400' : 'text-green-700'} font-normal`}>{elm?.status == 'authorized' ? 'success' : elm?.status}</p>
                                        <p className={`text-xs  text-left capitalize  font-normal`}>{elm?.xtype}</p>
                                        <H5 
                                            title={`${elm?.currency?.symbol} ${elm?.amount}`}
                                        />
                                    </div>
                                </div>  
                            ))  
                        ) : (
                            <div className='flex-1 w-full flex items-center justify-center'>
                                <H5
                                    title='No Transaction here'
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Billing
