import React, { useEffect, useState } from 'react'
import { H1, H2, H3, H5, H6, P, Price, Span } from '../Themes/StylingComponent'
import { useTheme } from '../Themes/ThemeProvider';
import { BasicPlanIcon, CancelIcon, EntPlanIcon, LeftArrowIcon, RightIcon } from '../Components/Icons';
import { Button, Switch, message } from 'antd';
import PlanCard from '../Components/PlanCard';
import { capitalizeAllWords, get, post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { useLocation, useNavigate } from 'react-router-dom';
import { PlanSkaleton } from './DocumentSkaleton';

const colors = [
    '#FFFFFF',

]

const PlanScreen = () => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const path = useLocation()
    const [allPlans, setAllPlans] = React.useState([])
    const [isYearly, setIsYEarly] = React.useState(true)
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [trial, setTrial] = React.useState(0);
    const [currency, setCurrency] = useState({});
    const [planLoading, setPlanLoading] = React.useState(true)

    // const [currency, setCurrency] = useState({
    //     code:'USD',
    //     symbol:'$'
    // });  
    const [defaultCurrency, setDefaultCurrency] = useState();

    const onChange = (checked) => {
        setIsYEarly(checked)
    };

    useEffect(() => {
        getPlans();
        document.title = capitalizeAllWords('Select Plans');
    }, [])

    // GET PLANS        
    const getPlans = async () => {
        try {
            const response = await get(`user/${authState?.id}/plans/${authState?.teamId}`)
            if (response.statusCode == 200) {
                setAllPlans(response?.data?.plans);
                setTrial(response?.data?.trialPeriod);
                setCurrency(response?.data?.currency);
                setDefaultCurrency(response?.data?.defaultCurrency)
                setPlanLoading(false)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETHCING PLANS ${e}`);
        }
    }

    // START TRIAL 
    const startTrial = async (planId) => {
        try {
            const _body = {
                teamId: authState?.teamId,
                planId: planId,
                userId: authState?.id
            }
            const response = await post('user/startTrial', _body)
            if (response?.statusCode == 200) {
                navigate('/home/dashboard')
                if (path?.state?.data) {
                    navigate('/settings/manage-team')
                } else {
                    navigate('/home/dashboard')
                }
            }
        } catch (e) {
            console.log(`ERROR WHILE STARTING TRIAL ${e}`);
        }
    }

    const contactUs = async () => {
        try {
            const response = await post(`team/${authState?.teamId}/user/${authState?.id}`)
            if (response?.statusCode == 200) {
                message.success(response?.data?.message)
            } else {
                message.error(response?.message)
            }
            console.log(response);
        } catch (e) {
            message.error(e?.response?.message)
            console.log(`ERROR WHILE CONTACTING ${e}`);
        }
    }

    return (
        <>
            <div className={`w-full py-14   ${themeColors?.bg?.primary}`}>
                {/* <div className={`w-full ${themeColors?.bg?.primary} fixed top-0 z-50 py-4 px-3 flex items-center gap-2 border-b ${themeColors.borders.fifth}`}>
                     <LeftArrowIcon color={themeColors.icons.sixth} /> 
                    <H3 title='Pricing Plans' />
                </div> */}
                <div className='w-full flex-1 overflow-y-auto flex px-3 items-center justify-center flex-col'>
                    <div className='w-full lg:w-1/2 flex items-center justify-center flex-col gap-4 py-4'>
                        <H1
                            title={<span>Pricing that meets the needs of businesses,<br /> regardless of size!</span>}
                            align='center'
                        />
                        <P
                            title="Our pricing is custom-made for every business, no matter its size. Whether you're a startup, a mid-sized company, or an enterprise, our plans are tailored to your needs. Say goodbye to generic pricing and welcome a model that evolves with you. Find your perfect fit with us, ensuring your investment matches your growth."
                            align='center'
                        />
                        <div className={`flex items-center gap-2 py-6`}>
                            <p className={`${isYearly ? themeColors.text.secondary : themeColors.text.fifth} `}>Monthly</p>
                            <Switch checked={isYearly} onChange={onChange} />
                            {/* <p className={`${isYearly ? 'text-gray-400' : 'text-red-500'}}`}>Yearly</p> */}
                            <p className={`${isYearly ? themeColors.text.fifth : themeColors.text.secondary} `}>Yearly</p>
                        </div>
                    </div>
                    <div className='w-full  lg:w-[70%] p-1 flex gap-4 md:gap:3  flex-wrap   justify-center sm:justify-start xl:justify-between   py-4'>
                        {planLoading ? <PlanSkaleton /> :
                            allPlans?.length > 0 ?
                                allPlans && allPlans?.map((plan, index) => {    
                                    return (
                                        <div className='w-full  lg:w-[40%] md:w-[48%] xl:w-[31%]  '>
                                            <PlanCard
                                                defaultCurrency={defaultCurrency}
                                                currency={currency} 
                                                setCurrency={setCurrency}
                                                key={index}
                                                plan={plan} 
                                                isYearly={isYearly}
                                                setIsYEarly={setIsYEarly}
                                                trial={trial}
                                                isSetting={path?.state?.data ? true : false}
                                                onStartTrial={(planId) => startTrial(planId)}
                                            />
                                        </div>
                                    )
                                })
                                : 'no plans'}

                    </div>

                    {/* ENTERPRICE PLAN CARD  */}
                    <div className='w-full lg:w-[70%] p-1'>
                        <div
                            // style={{ background: 'linear-gradient(#525252, #000000)' }}
                            className={`w-full bg-[#484848] rounded my-4  flex flex-col gap-4 p-6 shadow-lg`}
                        >
                            <div className='w-full items-center flex gap-2'>
                                <EntPlanIcon
                                    color={themeColors?.icons?.primary}
                                />
                                <h2 className='text-[#F7F7F7] tracking-wide font-normal text-lg lg:text-xl'>Enterprise</h2>
                            </div>
                            <p className='text-[#F7F7F7] py-4  text-xs lg:text-sm'>Our Enterprise Plan is tailored to meet the unique needs of your business. With customizable features and flexible pricing, we provide a solution that scales with your requirements. Contact us for a personalized quote and discover how Signizy can optimize your document workflows.</p>
                            <div className='flex items-end justify-end'>
                                <Button
                                    type='primary'
                                    size='large'
                                    onClick={() => {
                                        contactUs()
                                    }}
                                >
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PlanScreen
