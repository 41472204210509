import { Provider } from 'react-redux'; // Import Provider from react-redux
import { store, persistor } from './store/store'; // Import your Redux store
import { RecoilRoot } from 'recoil';
import React, { useEffect } from 'react';
import ThemeComponent from './Themes/ThemeComponent';
import { DimensionsProvider } from './DimensionContext/DimensionContext';
import MainRoutes from './Routes/MainRoutes';
import { PersistGate } from 'redux-persist/integration/react';
import { useLocation } from 'react-router-dom';

function App() {
// Dependency on location.pathname ensures the effect runs when the path changes
  return (
    <>
      <Provider store={store}> {/* Wrap with Provider and pass the Redux store */}
        <PersistGate loading={null} persistor={persistor} >
          <RecoilRoot>
            <DimensionsProvider>
              <ThemeComponent />
            </DimensionsProvider>
          </RecoilRoot> 
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
