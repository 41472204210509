import React from 'react'
import { EmmailIcon, PasswordIcon } from './Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, message } from 'antd';
import { Checkbox } from 'antd';
import { emailRegex, passwordRegex } from '../ValidationRegex';
import { capitalizeAllWords, get, post } from '../Services/api-services';
import { userAuthState } from '../Services/RecoilService';
import { useRecoilState } from 'recoil';
import { useTheme } from '../Themes/ThemeProvider';
import { H1, P } from '../Themes/StylingComponent';
import VerifyEmail from './VerifyEmail';
import VerifyEmailComponent from './VerifyEmailComponent';
import AnimationCards from './AnimationCards';
const inputStyle = { fontSize: '14px' }

const signUpData = {
    email: '',
    password: ''
}

const EmailSignup = () => {
    const navigate = useNavigate();
    const path = useLocation();
    const { themeColors } = useTheme();
    const [isMobileSignup, setIsMobileSIgnup] = React.useState(false);
    const [formData, setFormData] = React.useState({ ...signUpData })
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [isAgree, setIsAgree] = React.useState(false)
    const [isDisabled, setIsDisabled] = React.useState(true)
    const [open, setOpen] = React.useState(false)
    const [otp, setOtp] = React.useState('')
    const [count, setCount] = React.useState(30)
    const [currentUser, setCurrentUser] = React.useState('')
    const [data, setData] = React.useState('')

    // SET DISABLE 
    React.useEffect(() => {
        if (formData?.email?.length > 0 && formData?.password?.length > 0) {
            if (emailRegex?.test(formData?.email) && passwordRegex?.test(formData?.password) && isAgree) {
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
            }
        } else {
            setIsDisabled(true)
        }
    }, [formData?.email, formData?.password, isAgree])

    const onChange = (e) => {
        // console.log(`checked = ${e.target.checked}`);
        setIsAgree(e.target.checked)
    };

    const handleSignup = async () => {
        try {

            let _body = { ...formData };
            if (!_body?.email) {
                message.error('Enter Valid Email')
                return
            }
            if (!_body?.password) {
                message.error('Enter Valid Password')
                return
            }
            if (!isAgree) {
                message.error('Please Select Terms & conditions')
                return
            }
            _body.email = _body.email?.toLowerCase(); // Convert email to lowercase
            const response = await post('auth/signup', _body)
            if (response?.statusCode == 200) {
                setAuthState(response?.data)
                navigate('/mobile-signup')
            } else if (response?.statusCode == 206) {
                setAuthState(response?.data)
                navigate('/mobile-signup', { state: { data: 'invited' } });
            } else {
                message.error(response?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE SIGNUP ${e}`);
        }
    }

    // GET CURRENT USER DETAILS 
    const getCurrentUserDetails = async (id) => {
        try {
            const response = await get(`user/${id}`)
            if (response?.statusCode == 200) {
                setCurrentUser(response?.data)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING USER DETAILS ${e}`);
        }
    }

    // CHECK LOGIN 
    // React.useEffect(() => {
    //     if (authState?.id && authState?.teamId) {
    //         navigate('/home/dashboard')
    //     }
    // }, [])

    React.useEffect(() => {
        document.title = capitalizeAllWords('SignUp');
        if (authState?.id && authState?.teamId) {
            navigate('/home/dashboard')
        } else if (authState?.id) {
            navigate('/select-team', { replace: true })
        }
    }, [])

    return (
        <>
            <div className='w-full h-screen flex'>
                <div className='w-full lg:w-9/12 flex flex-col items-center justify-center'>
                    <div className='w-11/12 lg:w-2/6 flex flex-col  items-start gap-6'>
                        <div className='w-full flex flex-col items-center gap-5 py-2'>
                            <H1
                                font='normal'
                                title='CREATE AN ACCOUNT'
                            />
                            <P
                                align='center'
                                title='Get started with your Signizy account and begin sending documents for signatures! Easily sign your important documents with Signizy.'
                            />
                        </div>
                        <div className='w-full flex flex-col items-start gap-5 py-4 lg:gap-6 lg:py-6'>
                            <Input
                                size='large'
                                placeholder="Email"
                                prefix={<EmmailIcon color={themeColors.icons.secondary} />}
                                style={inputStyle}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                onPressEnter={handleSignup}
                                status={formData?.email?.length > 0 && !emailRegex?.test(formData.email) ? 'error' : ''}
                            />
                            <div className='w-full'>
                                <Input.Password
                                    size='large'
                                    placeholder="Password"
                                    prefix={<PasswordIcon />}
                                    style={inputStyle}
                                    onPressEnter={handleSignup}
                                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                    status={formData?.password?.length > 0 && !passwordRegex?.test(formData.password) ? 'error' : ''}
                                />
                                <p className={`text-xs mt-3 text-red-400 ${formData?.password?.length > 0 && !passwordRegex?.test(formData.password) ? 'opacity-100' : 'opacity-0'}`}>The password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, numbers, and symbols</p>
                            </div>
                            <Checkbox
                                value={isAgree}
                                onChange={onChange}
                            >
                                I agree to the <a className=' text-blue-500' target='_blank' href="https://signizy.com/terms-conditions/">terms & conditions</a>
                            </Checkbox>
                        </div>
                        <div className='w-full py-2 flex flex-col gap-3 '>
                            <Button
                                disabled={isDisabled}
                                onClick={handleSignup} size='large' style={{ width: '100%' }} type="primary">
                                Create New Account
                            </Button>
                            <P
                                align='center'
                                title={<span>Already have an account? <span onClick={() => navigate('/')} className='text-[#4a4848] font-semibold cursor-pointer'>Log In</span></span>}
                            />
                        </div>
                    </div>
                </div>
                <AnimationCards />
            </div>

        </>
    )
}

export default EmailSignup
