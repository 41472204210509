import * as React from 'react'
import { PeopleIcon } from '../Components/Icons';
import { Button, Input, Space, Select, AutoComplete } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import { Checkbox } from 'antd';
import { Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { get, patch, post } from '../Services/api-services';
import CountrySelectComponent from '../Components/CountrySelectComponent';
import SelectButton from '../Components/SelectButton';
import UploadButton from '../Components/UploadButton';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import AnimationCards from '../Components/AnimationCards';


const inputStyle = { fontSize: '14px', }

const payloadData = {
    name: '',
    logo: '',
    country: {}
}

const _methods = {
    methods: {
        draw: true,
        email: true,
        mobile: true
    }
}

const CreateTeam = () => {
    const navigate = useNavigate();
    // const [isOrg, setIsOrg] = React.useState(() => localStorage.getItem('isOrg'));
    const [isOrg, setIsOrg] = React.useState(() => {
        const storedValue = sessionStorage.getItem('isOrg');
        return storedValue ? JSON.parse(storedValue) : false;
    });
    const [isAgree, setIsAgree] = React.useState(false)
    const [logoUrl, setLogoUrl] = React.useState('');
    const [formData, setFormData] = React.useState({ ...payloadData });
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [methods, setMethods] = React.useState({ ..._methods })
    const [isSelected, setIsSelected] = React.useState(false)
    const [isDisabled, setIsDisabled] = React.useState(true)


    // CHECK FORM VALIDATION 
    React.useEffect(() => {
        if (formData?.name?.length > 0 && isAgree) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [isAgree, formData?.name])


    const [selectedCompanyCountry, setSelectedCompanyCountry] = React.useState({
        code: 'In',
        label: "India",
        phone: "91",
        phoneLength: 10
    });

    // SET COUNTRY DATA 
    React.useEffect(() => {
        setCountryData()
    }, [selectedCompanyCountry, isOrg, logoUrl])

    const setCountryData = () => {
        setFormData({
            ...formData,
            country: selectedCompanyCountry,
            logo: logoUrl
        })
    }


    // CREATE NEW  TEAM 
    const createTeam = async () => {
        try {
            let _data = { ...formData }
            if (logoUrl && (logoUrl.size / (1024 * 1024)) > 2) {
                message.error('Logo size cannot exceed 2 MB. Please select a smaller logo.');
                return;
            }

            if (logoUrl && logoUrl?.type !== 'image/gif') {
                message.error('can only upload image!')
                setLogoUrl('')
                return
            }

            let teamLogoFileName;
            if (logoUrl) {
                // Create a new FormData object
                try {
                    let formData = new FormData();
                    formData.append('file', logoUrl);

                    const response = await post('document/upload/logo', formData)
                    if (response?.statusCode == 200) {
                        // setLogoUrl(response?.data?.fileName)
                        teamLogoFileName = response?.data?.fileName
                    } else {
                        message.error(response?.message)   
                        return  
                    }
                } catch (e) {
                    console.log(`ERROR WHLILE UPLOADING LOGO ${e}`);
                    return  
                }
                // navigate('/home/dashboard', { state: { formData } });    
            }
            if (teamLogoFileName) _data.logo = teamLogoFileName

            const response = await post(`user/${authState?.id}/createTeam`, _data)
            if (response?.statusCode == 200) {
                message.success(response?.data?.message)
                setAuthState(prevState => ({
                    ...prevState, // Keep the existing data
                    teamId: response?.data?.id // Add the new team ID   
                }));
                navigate('/select-plan')
            }
            console.log(response);
        } catch (e) {
            console.log(`ERROR WHILE CREATING TEAM ${e}`);
        }
    }


    return (
        <div className='w-full h-screen flex'>
            <div className='w-full lg:w-9/12 flex flex-col items-center justify-center'>
                <div className='w-11/12 lg:w-4/12 flex flex-col  items-start gap-5 '>
                    <div className='flex flex-col  w-full items-center gap-5'>
                        <div className='w-full flex gap-3'>
                            <div className='w-1/4  flex items-start justify-end '>
                                <UploadButton
                                    icon={<PlusOutlined style={{ color: '#1677FF' }} />}
                                    title='Company Logo'
                                    image={logoUrl}
                                    setImage={setLogoUrl}
                                />
                            </div>
                            <div className='w-9/12 flex flex-col items-center justify-center gap-3 '>
                                <Input
                                    size='large'
                                    placeholder="organisation name *"
                                    prefix={<PeopleIcon color='#AAAAAA' />}
                                    style={inputStyle}
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            name: e.target.value
                                        });
                                    }}
                                />
                                {/* COMPANY COUNTRY  */}
                                <CountrySelectComponent
                                    selectedCountry={selectedCompanyCountry}
                                    setSelectedCountry={setSelectedCompanyCountry}
                                    isSelected={isSelected}
                                    setIsSelected={setIsSelected}
                                    methods={methods?.methods}
                                    profile={true}
                                />
                            </div>
                        </div>

                        <div className='flex w-full items-start gap-2 py-2'>
                            <Checkbox onChange={(e) => setIsAgree(e.target.checked)} />
                            <p className='text-[#8E8E8E] text-[10px]'>I certify that I am the designated signing authority for this organization, authorized to sign and manage documents on its behalf.</p>
                        </div>
                    </div>
                    <div className='w-full py-2'>
                        <Button
                            disabled={isDisabled}
                            size='large'
                            style={{ width: '100%' }}
                            type="primary"
                            onClick={createTeam}
                        >
                            Start Your Journey
                        </Button>
                    </div>
                </div>
            </div>
            <AnimationCards />
            {isSelected &&
                <div onClick={() => setIsSelected(false)} className='w-full  h-screen bg-transparent  fixed top-0 left-0 z-60'>
                </div>
            }
        </div >

    )
}

export default CreateTeam
