// RECOILING
import { atom, } from 'recoil'
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist({
    key: 'userData',
    storage: localStorage
})

export const userAuthState = atom({
    key: 'user',
    default: {
        token: null,
        userId: ''
    },
    effects_UNSTABLE: [persistAtom],    
})




