import React from 'react'

const AnimationCards = () => {
    return (
        <>
            <div className='hidden lg:flex flex-col items-center justify-center px-4 gap-6 w-3/12 h-full bg-[#F1F6FF]'>
                <div className='w-full bg-white flex flex-col items-start gap-3 py-6  px-6 rounded shadow'>
                    <h1 className='text-base  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Empower Your Signers with</span> Phone and Email OTP Verification</h1>
                </div>
                <div className='w-full bg-white flex flex-col items-start gap-3 py-6  px-6 rounded shadow'>
                    <h1 className='text-base  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Simplify</span> Your Signing Process with Signizy</h1>
                </div>
                <div className='w-full bg-white flex flex-col items-start gap-3 py-6  px-6 rounded shadow'>
                    <h1 className='text-base  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Simple, Convenient</span> Pricing for Every Need</h1>
                </div>
                <div className='w-full bg-white flex flex-col items-start gap-3 py-6  px-6 rounded shadow'>
                    <h1 className='text-base  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Effortless Document Creation with</span> Upload and Write Yourself</h1>
                </div>
                <div className='w-full bg-white flex flex-col items-start gap-3 py-6  px-6 rounded shadow'>
                    <h1 className='text-base  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Collaborate Seamlessly with</span> Unlimited Signers</h1>
                </div>
                <div className='w-full bg-white flex flex-col items-start gap-3 py-6  px-6 rounded shadow'>
                    <h1 className='text-base  text-[#424D51] font-medium'><span className='text-[#AAAAAA]'>Print and Download</span> Your Documents</h1>
                </div>
            </div>
        </>
    )
}

export default AnimationCards
