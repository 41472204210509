import React from 'react'
import { DownloadDocIcon } from './Icons'
import { useDimensions } from '../DimensionContext/DimensionContext';
import { Button, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
const signizyLogo = require('../assets/Images/Signizy-logo-200-light.png')
const signizyLogoMobile = require('../assets/Images/MobileWhiteLogo.png')

const HeaderComponent = ({ title, createdBy, onDownload, _documentid }) => {
    const navigate = useNavigate(); 
    const { lg, xl, xxl, sm, xs } = useDimensions();

    const truncatedTitle = title.length > 15 ? title.substring(0, 15) + '...' : title;

    return (    
        <>
            <div
                className='px-3 py-2 fixed top-0 z-50 w-full bg-[rgb(66,77,81)] flex items-center justify-between'
            >
                <div className=' flex flex-col  items-start '>
                    <Tooltip title={title.length > 15 ? title : ''}>
                        <h6 className='text-white text-lg  font-normal'>{truncatedTitle}</h6>
                    </Tooltip>
                    {createdBy ?
                        <p className=' text-[10px] text-white font-normal'>Sent By {createdBy}</p>
                        : null}
                </div>
                <div className='flex  h-10 items-center gap-2'>
                    {xl || xxl || lg ?
                        <img className='h-full object-cover' src={signizyLogo} alt="signizyLogo" />
                        :
                        <img className='h-full object-cover' src={signizyLogoMobile} alt="signizyLogoMobile" />
                    }
                </div>
                <Button
                    type='text'
                    shape='circle'
                    // onClick={() => onDownload(title)}
                    onClick={() => {
                        navigate(`/documents/download/${_documentid}`)
                    }}
                    icon={<DownloadDocIcon />}
                />
            </div>
        </>
    )
}

export default HeaderComponent
