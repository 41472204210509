import React from 'react'
import { Button, Input, Layout, Skeleton, Tooltip } from 'antd';
import { useDimensions } from '../DimensionContext/DimensionContext';
import { useDispatch, useSelector } from 'react-redux';
import { usepath, useNavigate, useLocation } from 'react-router-dom';
import { EditTextIcon, LeftArrowIcon } from './Icons';
import { useTheme } from '@emotion/react';
import { updateIsWriting, updateTitle } from '../store/Reducers/contentReducer';
import ConfirmCancelDialogue from './ConfirmCancelDialogue';
import { docTitleLength } from '../ValidationRegex';
const { Header } = Layout;

const CreateHeader = ({ isSigned, dataLoading, isEditable }) => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions();
    const path = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _route = path.pathname.split('/')[2]
    const _documentid = path?.pathname?.includes('edit') ? path.pathname.split('/')[3] : path.pathname.split('/')[2]
    const { title, isWriting } = useSelector(state => state.contentOption);
    const [open, setOpen] = React.useState(false)
    let truncatedTitle;

    const inputStyle = {
        fontSize: lg || xl || xxl ? '25px' : '20px',
        borderRadius: 4,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 4px'
    }

    // update title 
    const handleTitleChange = (e) => {
        dispatch(updateTitle(e.target.value)); // Dispatch the updateTitle action with the new title
    };

    if (lg || xl || xxl) {
        truncatedTitle = title.length > 50 ? title.substring(0, 50) + '...' : title;
    } else {
        truncatedTitle = title.length > 20 ? title.substring(0, 20) + '...' : title;
    }   

    // console.log(isEditable);
    // console.log(!isSigned );

    return (    
        <>
            <Header
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '40px 0px',
                    paddingTop: lg || xl || xxl ? '60px' : '40px',
                    paddingLeft: 0,
                    paddingRight: 0,
                    width: '100%',
                    // maxWidth: '1600px', 
                    background: 'transparent'
                    // backgroundColor: (isWriting || path?.pathname?.includes('document') || typeof _documentid !== 'string') && path?.pathname?.includes(_documentid) ? '#F9FBFD' : '#FFFFFF'
                    // backgroundColor: isWriting || path?.pathname?.includes('document') || typeof _documentid != 'string' ? path?.pathname?.includes(_documentid) : false ? '#F9FBFD' : '#FFFFFF',
                    // backgroundColor: '#FFFFFF',
                }}
            >   
                <div className='flex flex-col lg:flex-row w-full items-start justify-center lg:items-center gap-5 lg:gap-8 mt-5 lg:mt-0 '>
                    {dataLoading ?
                        <div className='w-[950px] mx-auto'>
                            <Skeleton.Button active={true} size={'large'} block={true} />
                        </div>
                        :
                        <div className=' w-full  flex items-center justify-center '>
                            <div className='w-full '>
                                {_route !== 'write' && _route !== 'options' ?
                                    <div className='w-full max-w-[1000px] mx-auto flex items-center justify-between'>
                                        <div className='flex items-center gap-2'>
                                            <Button
                                                onClick={() => {
                                                    if (_route !== 'write' && _route !== 'options') {
                                                        navigate('/home/dashboard')
                                                    } else {
                                                        setOpen(true)
                                                    }
                                                }}
                                                type="text"
                                                icon={<LeftArrowIcon fontSize={'10px'} color={'#000000'} />}
                                                style={{
                                                    padding: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                            </Button>
                                            <Tooltip title={title?.length > 50 ? title : ''}>
                                                <h1 className='text-xl lg:text-2xl '>{truncatedTitle}</h1>
                                            </Tooltip>  
                                        </div>  
                                        {!isSigned && isEditable ?
                                            <Button
                                                type="text"
                                                shape="circle"
                                                icon={<EditTextIcon />}
                                                onClick={() => {
                                                    navigate(`/create-doc/options/${_documentid}`)
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: "center",
                                                    justifyContent: 'center'
                                                }}  
                                            />  
                                            : null} 
                                    </div>
                                    :
                                    <div className='flex relative items-center '>
                                        <Button
                                            onClick={() => {
                                                setOpen(true)
                                                // dispatch(updateIsWriting(false))         
                                            }}
                                            type="text"
                                            style={{
                                                position: 'absolute',
                                                top: '40%',
                                                left: 0,
                                                transform: 'translate(0%,-50%)'
                                            }}
                                            icon={<LeftArrowIcon color={'#000000'} />}
                                        >
                                        </Button>   
                                        {/* <div className='w-full'> */}
                                        <div className='w-full max-w-[1000px] mx-auto'>
                                            <Input
                                                size='large'
                                                value={title}
                                                placeholder="Your title here"
                                                style={inputStyle}
                                                maxLength={docTitleLength}
                                                onChange={handleTitleChange}
                                            // readOnly={isReadOnly}
                                            />  
                                        </div>  
                                        {/* </div> */}
                                    </div>
                                }   
                            </div>
                        </div>
                    }
                </div>
            </Header>   

            <ConfirmCancelDialogue
                open={open}
                setOpen={setOpen}
                onDiscard={() => {
                    dispatch(updateIsWriting(false))
                    setOpen(false)
                    navigate('/home/dashboard')
                }}
            />  
        </>
    )
}

export default CreateHeader
