import * as React from 'react'
import { BrowserRouter } from 'react-router-dom';
// import './App.css';
import MainRoutes from '../Routes/MainRoutes';
import { ThemeProvider } from './ThemeProvider';
// import { darkTheme, lightTheme } from './Theme';

function ThemeComponent() {

    return (
        <>
            <ThemeProvider>
                <BrowserRouter>
                    <div className="h-full flex flex-col ">
                        <MainRoutes />
                    </div>
                </BrowserRouter>
            </ThemeProvider >
        </>
    );
}

export default ThemeComponent;
