import React from 'react'
import { DrawIcon, EmailOptionIcon, MobileOptionIcon, RightIcon } from './Icons'
import { useDimensions } from '../DimensionContext/DimensionContext';
import { formatDateFunction } from '../Services/api-services';
import { useTheme } from '../Themes/ThemeProvider';
import { Tooltip } from 'antd';

const SignerComponent = ({ name, title, sign, currentSigner }) => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions();

    let truncatedName = name?.length > 15 ? name.substring(0, 15) + '...' : name

    return (
        <>
            <div className='w-full flex items-center justify-between py-3'>
                <div className='flex items-start gap-2'>
                    <div className='mt-2'>
                        {sign?.status &&
                            <RightIcon />
                        }   
                    </div>
                    <div className='flex flex-col items-start gap-1 max-w-[65%] lg:max-w-[100%]'>
                        <Tooltip title={name?.length > 20 ? name : ''}>
                            <h3 className={`text-sm whitespace-nowrap lg:text-lg capitalize font-medium ${themeColors.headings.primary}`}>
                                {currentSigner?.name === name ? `${truncatedName} (You)` : truncatedName}
                            </h3>
                        </Tooltip>
                        <div className='flex  gap-1'>
                            {/* <p className={`text-xs lg:text-sm ${themeColors.text.fourth}`}>Title: </p> */}
                            <Tooltip title={title?.length > 15 ? title : ''}>
                                <p className={`text-xs whitespace-nowrap lg:text-sm ${themeColors.text.fourth}`}>
                                    {title.length > 15 ? title.substring(0, 15) + '...' : title}
                                </p>
                            </Tooltip>
                        </div>
                    </div>
                </div>  
                {sign?.status ?
                    <div className='flex w-fit lg:w-1/5 items-center  gap-2'>
                        {
                            sign?.type === 'email' ? <EmailOptionIcon /> :
                                sign?.type === 'mobile' ? <MobileOptionIcon /> :
                                    <DrawIcon />
                        }
                        <div className=''>
                            <h6 className={`text-xs ${themeColors.headings.primary}`} >
                                {
                                    sign?.type === 'email' ? sign?.email?.length > 15 ? sign?.email?.substring(0, 15) + '...' : sign?.email :
                                        sign?.type === 'mobile' ? `+${sign?.country?.phone} ${sign?.mobile}` :
                                            <div className='w-16 h-14  flex items-center justify-center '>
                                                <img className='h-full w-full object-contain' src={`data:image/svg+xml;utf8,${encodeURIComponent(sign?.svg)}`} alt="SVG Image" />
                                            </div>
                                }      
                            </h6>
                            <p className={`text-[10px] ${themeColors.text.fourth}`} >
                                {formatDateFunction(sign?.date)}
                            </p>
                        </div>
                    </div>
                    : null}

            </div>
        </>
    )
}

export default SignerComponent  
