import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import OtpInput from 'react-otp-input';
import { useTheme } from '../Themes/ThemeProvider';
import { H2 } from '../Themes/StylingComponent';
import { otpRegex } from '../ValidationRegex';

const VerifyEmailComponent = ({ open, setOpen, otp, onSendOtp, onVerifyOtp, count, setOtp, email }) => {
    const { themeColors } = useTheme();

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };

    return (
        <>
            <Modal
                title={<div className='flex items-center justify-center'><h1 className='text-2xl'>Enter Verification Code</h1></div>}
                centered
                closable={false}
                open={open}
                onOk={() => setOpen(false)}
                // onCancel={() => setOpen(false)}
                style={modalContentStyle}
                footer={
                    <div className='w-full  flex flex-col items-center justify-center gap-5'>
                        <p className={`text-xs font-normal ${themeColors.text.secondary}`}>Didn't receive code
                            <Button
                                type="link" 
                                style={{
                                    padding: 0
                                }}
                                disabled={count != 30 ? true : false}
                                onClick={onSendOtp}
                            >
                                Resend
                            </Button>
                            <span className={`font-semibold ${themeColors.text.fifth}`} ></span>
                        </p>
                        <div className='w-full flex items-center justify-between gap-4 '>
                            <Button onClick={() => setOpen(false)} size='middle' style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }} type="primary">Skip</Button>
                            <Button
                                disabled={!otp?.length > 0 && !otpRegex.test(otp)}
                                onClick={onVerifyOtp}   
                                size='middle'
                                type="primary"
                            >
                                Verify Code
                            </Button>
                        </div>
                    </div >
                }
                width={500}
            >
                <div className='w-full  flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    <h1 className={`text-center ${themeColors.headings.primary}`}>Please enter the Verification Code sent to your email address ending in {email} to complete your Email verification!</h1>
                    <div className='w-full flex flex-col gap-3 items-center py-8 justify-center'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            // onPaste={true}
                            onPaste={(e) => {
                                const pastedText = e.clipboardData.getData('text');
                                setOtp(e?.clipboardData?.getData('text'))
                                // Now you can use the pastedText variable to handle the pasted content
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    // varifyOtp();
                                }
                            }}
                            inputType='text'
                            shouldAutoFocus={false}
                            // helperText={otp?.length > 0 && !otpRegex?.test(otp) ? "Please Enter Valid OTP" : ""}
                            // error={otp?.length > 0 && !otpRegex?.test(otp)}
                            numInputs={4}
                            // containerStyle='outline'
                            separator=''
                            renderSeparator={<span> </span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                width: '60px',
                                height: '60px',
                                margin: '0 8px',
                                borderRadius: '4px',
                                fontSize: '20px',
                                textAlign: 'center',
                                fontWeight: '600',
                                outline: 'none',
                                // background: '#55555526',
                                border: `1px solid ${themeColors.borders.otpInput}`,
                                color: '#555555',
                            }}
                        />
                        <p className={`text-center ${themeColors.text.fourth}`}>00:{count}</p>
                    </div>
                </div>
            </Modal >
        </>
    );
};
export default VerifyEmailComponent;