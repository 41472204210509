import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';


const SendOtpDialogue = ({ open, setOpen, currentEmail, onSendOtp, _type, setOpenVerifyOtp, currentSigner }) => {
    const { themeColors } = useTheme();

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };

    return (
        <>
            <Modal
                title={<div className='flex items-center justify-center'><h1 className='text-2xl'>Send OTP</h1></div>}
                centered
                closable={false}
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                style={modalContentStyle}
                footer={
                    <div className='w-full flex items-center justify-center gap-4 pt-6'>
                        <Button
                            onClick={() => setOpen(false)}
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onSendOtp();
                            }}
                            style={{ backgroundColor: themeColors.bg.inviteButton }}
                            size='middle' type="primary"
                        >
                            Send OTP
                        </Button>
                    </div>
                }
                width={500}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    <h1 className='text-center'>To verify step forward, please click 'Send OTP.' We will send the OTP to {_type == 'email' ? currentEmail : <spa className='whitespace-nowrap'>+{currentSigner?.country?.phone}{currentSigner?.mobile}</spa>}</h1>
                </div>
            </Modal>
        </>
    );
};
export default SendOtpDialogue;