import React from 'react'
import { CompletedIcon, MoreIcon, RemoveIcon, RoleIcon } from '../Components/Icons';
import { Button, Dropdown, Space } from 'antd';
import { useTheme } from '../Themes/ThemeProvider';
import { formatDateFunction, getDate, getDateYear } from '../Services/api-services';
import { H5, H6, P, XS } from '../Themes/StylingComponent';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';

const UserListRow = ({ open, setOpen, user, changeRole, setSelectedUserData, setChangeRole }) => {
    const { themeColors } = useTheme();
    const [authState, setAuthState] = useRecoilState(userAuthState)

    // MENU ITEMS 
    const items = [
        {
            label: (
                <div onClick={() => setChangeRole(true)} className='flex items-center gap-2'>
                    <RoleIcon color={themeColors.icons.fifth} />
                    <h1 className={`${themeColors.headings.primary}`}>Change role</h1>
                </div>
            ),
            key: '0',
        },
        {
            label: (
                <div onClick={() => setOpen(true)} className='flex items-center gap-2'>
                    <RemoveIcon color={themeColors.icons.fifth} />
                    <h1 className={`${themeColors.headings.primary}`}>Remove User</h1>
                </div>
            ),
            key: '1',
        },

    ];

    return (
        <>
            <div className='flex w-full border-b border-b-[#00000008] items-center justify-between  px-3 py-6 lg:py-4'>
                <div className='flex items-start gap-2 lg:gap-4'>
                    <div className='flex items-start flex-col'>
                        <H5
                            title={user?.user?.name?.length > 20 ? user?.user?.name.substring(0, 20) + '...' : user?.user?.name}
                            font='medium'
                            toolTipTitle={user?.user?.name?.length > 20 ? user?.user?.name : ''}
                        />
                        <XS
                            title={user?.user?.email.length > 20 ? user?.user?.email.substring(0, 20) + '...' : user?.user?.email}
                            font='normal'
                            color={themeColors?.text?.draft}
                            toolTipTitle={user?.user?.email?.length > 20 ? user?.user?.email : ''}
                        />
                    </div>
                </div>
                <div className='flex items-center gap-3'>
                    <div className='flex flex-col items-start '>
                        <H6
                            title={user?.role.length > 20 ? user?.role.substring(0, 20) + '...' : user?.role}
                            toolTipTitle={user?.role?.length > 20 ? user?.role : ''}
                        />
                        <XS
                            title={formatDateFunction(user?.user?.createdAt)}
                            font='normal'
                            color={themeColors?.text?.draft}    
                        />
                    </div>
                    {/* <p className={`text-xs lg:text-sm font-normal ${themeColors.text.secondary}`}>{user?.role}</p> */}
                    {authState?.role != 'moderator' && user?.role != 'owner' && user?.user?._id != authState?.id ?
                        < Dropdown
                            menu={{
                                items,
                            }}
                            trigger={['click']}
                        >
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedUserData(user)
                                }}
                                type='link' shape='circle'
                            >
                                <MoreIcon color={themeColors.icons.fifth} />
                            </Button>
                        </Dropdown> 
                        :
                        <div className='p-4'></div>
                    }
                </div>
            </div >
        </>
    )
}

export default UserListRow
