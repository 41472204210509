import React from 'react'
import { Button } from 'antd'
import { capitalizeAllWords, post } from '../Services/api-services'
import { useLocation, useNavigate } from 'react-router-dom'
import useRazorpay from "react-razorpay";
export default function Payments({ plan, isYearly, currency, userId, teamId, isSetting, isBilling }) {
  const [isLoading, setIsLoading] = React.useState(false)
  const location = useLocation();

  const payment = async (plan) => {
    setIsLoading(true)
    try {
      let body = {
        planId: plan._id,
        currency: currency,
        isYearly: isYearly,
        userId: userId,
        teamId: teamId
      }
      const res = await post('plan/create-order', body);
      setIsLoading(false)
      if (res.statusCode === 200) {
        const a = await handlePayment(res.data);
      } else if (res.statusCode === 201) {
        // if (isBilling) {
        //   navigate('/settings/billing')
        // } else if (isSetting) {
        //   navigate('/settings/manage-team')
        // }
        navigate('/')
      }

    } catch (e) {
      throw (e)
    }
  }

  const [Razorpay] = useRazorpay();
  let navigate = useNavigate();
  const handlePayment = async (params) => {
    let key_id = 'rzp_live_g1Y8cp40Omj3A2';

    const options = {
      key: key_id, // Enter the Key ID generated from the Dashboard
      amount: params?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: params?.currency,
      name: "Signizy",
      description: "Test Transaction",
      image: "https://api.signizy.com/public/img/image.png",
      order_id: params.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        let body = { isSetting, success: true, isBilling, orderId: params.id }
        navigate('/payment_status', { state: body }, { replace: true })
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      let body = { isSetting, success: false, isBilling, orderData: params }
      navigate('/payment_status', { state: body }, { replace: true })
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    // rzp1.on("payment.authorized", function (response) {
    // })

    rzp1.open();
  };

  React.useEffect(() => {
    document.title = capitalizeAllWords('Payment');
  }, [])


  return (
    <div className='w-full flex py-2 items-center justify-between'>
      <Button
        type="default"
        style={{
          // width: '13%'
        }}
        size='large'
        // onClick={() => payment(plan)}
        onClick={() => {
          if (location?.pathname?.includes('billing')) {
            navigate('/settings/billing')
          } else {
            navigate('/select-plan')
          } 
          // navigate(-1)
          // navigate('/settings/billing')
        }
        }
      >
        Cancel
      </Button>
      <Button
        type="primary"
        style={{
          // width: '13%'
        }}
        size='large'
        onClick={() => payment(plan)}
        loading={isLoading}
      >
        Proceed to Pay
      </Button>
    </div>
  )
}
