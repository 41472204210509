import React from 'react'
import { useTheme } from '@mui/system';
import { Input, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BASEURL, post } from '../Services/api-services';
import { UserOutlined } from '@ant-design/icons';

const UploadButton = ({ title, icon, image, setImage, logoUrl }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const inputRef = React.useRef(null);

    const handleButtonClick = () => {
        inputRef.current.input.click();
    };

    return (
        <>
            <div
                onClick={handleButtonClick}
                className={`w-full h-full  ${!(image || logoUrl) ? 'bg-[#F1F6FF]' : ''} overflow-hidden ${(image || logoUrl) ? 'border-none' : 'border'}  border-dashed border-[#1677FF73] ${(image || logoUrl) ? 'p-0' : 'p-3'}  gap-3 lg:gap-2 rounded-xl flex flex-col items-center justify-center`}
            >
                {image ?
                    <div >
                        <img src={URL.createObjectURL(image)} alt="Selected File" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                    :
                    <>
                        {logoUrl ?
                            <img
                                className='w-full h-full object-contain'
                                src={`${BASEURL}public/logos/${logoUrl}`} alt={image}
                                onError={(e) => { e.target.src = <UserOutlined /> }}
                            />
                            :
                            <>
                                {icon}
                                <h1 className='text-xs font-normal text-center text-[#8EBDFF] lg:text-xs'>{title}</h1>
                            </>
                        }
                    </>
                }
            </div>
            <Input  
                ref={inputRef}
                type='file'
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => {
                    if (e.target.files[0] && e.target.files[0].type == 'image/gif') {   
                        message.error('can only upload image!')
                        return
                    }
                    setImage(e.target.files[0])
                }}
            />
        </>
    )
}

export default UploadButton
