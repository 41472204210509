import React, { useState } from 'react'
import { BasicPlanIcon, EntPlanIcon } from './Icons'
import { Button, Tooltip } from 'antd'
import { useTheme } from '../Themes/ThemeProvider'
import { P } from '../Themes/StylingComponent'
import { useNavigate } from 'react-router-dom'

const SubscriptionCard = ({ plan, currency, credits, allData, isSetting }) => {
    const { themeColors } = useTheme();
    const [isYearly, setIsYEarly] = useState(true);
    const [price, setPrice] = useState(plan.price[currency?.code]);
    const credit = credits ? credits : 0;
    const navigate = useNavigate();

    const handleCurrentPlanUpgrade = async (data) => {
        try {
            // let plan;
            // for (let index = 0; index < data?.plans.length; index++) {
            //     const element = data?.plans[index];
            //     if (element?._id === data?.currectPlanId) {
            //         plan = element
            //     }

            // }
            let isYearly = true
            let isBilling = true
            let body = { plan, currency, isYearly, credits, isBilling }
            navigate('pay_now', { state: body })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <div className='w-full md:w-1/2 py-5 lg:w-[40%] xl:w-[20%] min-h-80 shrink-0 p-1 '>
                <div
                    className={`w-full h-full ${plan?.currentPlan ? themeColors?.bg.primary : themeColors?.bg.selecttButton} rounded my-4 shadow flex flex-col gap-4 p-6 min-h-72 items-start justify-between `}
                >
                    <div className='w-full items-center flex gap-2'>
                        <BasicPlanIcon
                            color={plan?.currentPlan ? themeColors?.icons?.primary : themeColors?.icons?.seven}
                        // color={themeColors?.icons?.secondary}
                        />
                        <h2 className={`${plan?.currentPlan ? themeColors?.headings?.primary : themeColors?.headings?.third} tracking-wide capitalize font-normal text-lg lg:text-xl`}>{plan?.title}</h2>
                    </div>
                    {plan?.description ?    
                        <p
                            className={`${plan?.currentPlan ? themeColors?.text?.fourth : themeColors?.text?.plan} font-normal py-4 text-center text-xs lg:text-xs `}
                            dangerouslySetInnerHTML={{ __html: plan.description }}
                        />
                        : null}
                    <div className='flex w-full flex-col gap-2 items-end justify-center'>
                        {!plan?.currentPlan ?
                            <h6 className={`text-white text-center mx-auto text-2xl`}>{currency?.symbol}{((price?.offerYearlyPrice - credit) / 12).toFixed(2)}/month</h6>
                            : null}
                        {plan?.currentPlan ?
                            <Button
                                style={{
                                    width: '100%',
                                    background: themeColors?.icons?.current,
                                    color: themeColors?.icons?.primary,
                                    fontWeight: 700,
                                    borderRadius: 3,
                                    whiteSpace: 'wrap'
                                }}
                                type='primary'
                                size='large'
                            >
                                CURRENT PLAN
                            </Button>
                            :
                            <Button
                                onClick={() => {
                                    handleCurrentPlanUpgrade(allData)
                                }}
                                style={{
                                    width: '100%',
                                    background: themeColors?.icons?.seven,
                                    color: themeColors?.icons?.primary,
                                    fontWeight: 700,
                                    borderRadius: 3,
                                    whiteSpace: 'wrap'
                                }}
                                size='large'
                            >
                                UPGRADE NOW
                                {/* <h6 className=' '> UPGRADE for</h6> {currency?.symbol}{((price?.offerYearlyPrice - credit) / 12).toFixed(2)}/month */}
                            </Button>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}

export default SubscriptionCard
