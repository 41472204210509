import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from '../Themes/ThemeProvider';
import { H1, P } from '../Themes/StylingComponent';
import { Button, Input, message } from 'antd';
import { capitalizeAllWords, post } from '../Services/api-services';
import { useEffect } from 'react';
import { passwordRegex } from '../ValidationRegex';

const inputStyle = { fontSize: '14px' }

const _formData = {
    email: '',
    otp: '',
    password: '',
    confirmPassword: ''
}


const ChangePassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { themeColors } = useTheme();
    const receivedData = location.state?.data;
    const [otp, setOtp] = React.useState('')
    const [formData, setFormData] = React.useState({ ..._formData })
    const [count, setCount] = React.useState(30);


    useEffect(() => {
        setFormData({
            ...formData,
            email: receivedData?.email,
            otp: receivedData?.otp
        })
    }, [receivedData])


    // CHANGE PASSWORD 
    const chagePassword = async () => {
        try {
            const _body = {
                email: formData?.email,
                otp: formData?.otp,
                password: formData?.password,
                confirmPassword: formData?.confirmPassword
            }
            const response = await post('auth/changePassword', _body)
            if (response?.statusCode == 200) {
                message.success('password successfully changed')
                navigate('/')
            }
        } catch (e) {
            console.log(`ERROR WHILE VERIFYING OTP ${e}`);
        }
    }

    React.useEffect(() => {
        document.title = capitalizeAllWords('Change Password');
    }, [])

    return (
        <div className='w-full h-screen flex'>
            <div className='w-full lg:w-9/12 flex flex-col items-center justify-center'>
                <div className='w-11/12 lg:w-2/6 flex flex-col  items-start gap-6'>
                    <div className='w-full flex flex-col items-center gap-5 py-2'>
                        <H1 title='CHANGE PASSWORD' />
                        {/* <P  
                            align='center'
                            title='Get started with your Signizy account and begin sending documents for signatures! Easily sign your important documents with Signizy.'
                        /> */}
                    </div>
                    <div className='w-full flex flex-col items-start gap-5 py-4 lg:gap-3 '>
                        <div className='w-full'>
                            <Input.Password
                                size='large'
                                style={inputStyle}
                                placeholder="New Password"
                                // prefix={<PasswordIcon color={themeColors.icons.secondary} />}
                                onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                status={formData?.password?.length > 0 && !passwordRegex?.test(formData.password) ? 'error' : ''}
                            />
                            <p className={`text-xs mt-3 text-red-400 ${formData?.password?.length > 0 && !passwordRegex?.test(formData.password) ? 'opacity-100' : 'opacity-0'}`}>The password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, numbers, and symbols</p>
                        </div>
                        <Input.Password
                            size='large'
                            style={inputStyle}
                            placeholder="Confirm Password"
                            // prefix={<PasswordIcon color={themeColors.icons.secondary} />}
                            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                            status={formData?.confirmPassword?.length > 0 && formData?.confirmPassword != formData?.password ? 'error' : ''}
                        />  
                    </div>
                    <div className='w-full'>
                        <Button
                            onClick={chagePassword}
                            disabled={!(passwordRegex.test(formData?.password) && passwordRegex.test(formData?.confirmPassword) && formData?.password == formData?.confirmPassword)}
                            size='large' style={{ width: '100%' }}
                            type="primary"
                        >
                            Change Password
                        </Button>
                    </div>
                </div>
            </div>
            <div className='hidden lg:block w-3/12 h-full bg-[#F1F6FF]'></div>
        </div >
    )
}

export default ChangePassword

