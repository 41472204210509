import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PasswordIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';
import CountrySelectComponent from './CountrySelectComponent';
import { post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { passwordRegex } from '../ValidationRegex';

const { Option } = Select;

const inputStyle = { fontSize: '14px' }

const ChangePasswordDialogue = ({ open, setOpen, changePassword, formData, setFormData }) => {
    const { themeColors } = useTheme();
    const [isValid, setIsValid] = React.useState(false)

    // FORM LABEL HEADING 
    const FormHeading = ({ title }) => (
        <h6 className={`text-xs lg:text-[15px] ${themeColors.headings.primary}`}>{title}</h6>
    )

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0',
        // maxWidth: '30vw'
    };

    React.useEffect(() => {
        if (formData?.confirmPassword?.length > 0 && formData?.password?.length > 0 && passwordRegex.test(formData?.password) && passwordRegex.test(formData?.confirmPassword) && formData?.confirmPassword == formData?.password) {
            setIsValid(true)
        } else {
            if (formData?.confirmPassword?.length > 0)
                setIsValid(false)
        }
    }, [formData])

    return (
        <>
            <Modal
                title={<h1>Change Password</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div className={`w-full flex items-center justify-between pt-6 `}>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                setFormData({
                                    password: '',
                                    confirmPassword: ''
                                })
                                setIsValid(false)
                            }}
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => changePassword()}
                            // style={{ backgroundColor: themeColors.bg.inviteButton }}
                            size='middle'
                            type="primary"
                            disabled={!isValid}
                        >
                            Save
                        </Button>
                    </div>
                }
                header={null}
                width={800}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>

                    {/* PADSWORD FIELD  */}
                    <div className='w-full  flex flex-col items-start gap-2'>
                        <FormHeading title='Password' />
                        <div className='w-full'>
                            <Input.Password 
                                size='large'
                                style={inputStyle}
                                placeholder="Password"  
                                value={formData?.password}  
                                prefix={<PasswordIcon />}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        password: e?.target?.value
                                    })
                                }}
                                status={formData?.password?.length > 0 && !passwordRegex.test(formData?.password) ? 'error' : ''}
                            />
                            <p className={`text-xs mt-3 text-red-400 ${formData?.password?.length > 0 && !passwordRegex?.test(formData.password) ? 'opacity-100' : 'opacity-0'}`}>The password must be at least 8 characters long and contain a combination of uppercase and lowercase letters, numbers, and symbols</p>
                        </div>
                    </div>

                    {/* CONFIRM PADSWORD FIELD  */}
                    <div className='w-full  flex flex-col items-start gap-2'>
                        <FormHeading title='Confirm Password' />
                        <div className='w-full'>
                            <Input.Password
                                size='large'
                                style={inputStyle}
                                placeholder="Confirm Password"
                                value={formData?.confirmPassword}
                                prefix={<PasswordIcon />}
                                status={formData?.confirmPassword?.length > 0 && !isValid ? 'error' : ''}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        confirmPassword: e?.target?.value
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    );
};
export default ChangePasswordDialogue;