import { Modal } from 'antd';
import React from 'react';
import { useDimensions } from '../DimensionContext/DimensionContext';
// import { useMediaQuery } from 'react-responsive';

const ResponsiveModal = ({ open, onCancel }) => {
    // Define the media query for mobile devices
    const { lg, xl, xxl } = useDimensions();
    const isMobile = lg || xl || xxl ? false : true;

    // Define styles for the modal content based on the screen size
    const modalStyle = {    
        width: isMobile ? '100vw' : '80%', // Adjust width for mobile and desktop   
        height: isMobile ? '100vh' : 'auto', // Adjust height for mobile and desktop    
        margin: isMobile ? 0 : 'auto', // Center horizontally on desktop    
    };      

    return (    
        <Modal  
            title="Responsive Modal"    
            open={open} 
            onCancel={onCancel}
            footer={null}
            style={modalStyle} // Apply the dynamic styles
            bodyStyle={{ overflow: 'auto', height: '100%' }} // Ensure content scrolls on mobile
        >
            <p>This is the content of the modal.</p>    
        </Modal>
    );
};

export default ResponsiveModal;
