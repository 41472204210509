import React, { useEffect, useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';
import CountrySelectComponent from './CountrySelectComponent';
import { post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import UploadButton from './UploadButton';
import { PlusOutlined } from '@ant-design/icons';
import { nameRegex, teamNameLength } from '../ValidationRegex';

const { Option } = Select;

const inputStyle = { fontSize: '14px' }

const AddTeamDialogue = ({ open, setOpen, selectedCountry, isLoading, formData, setFormData, setIsSelected, logoUrl, setLogoUrl, setSelectedCountry, isSelected, createTeam }) => {
    const { themeColors } = useTheme();
    const textDiv = React.useRef();
    const [height, setHeight] = React.useState('')
    const [_isSelected, setIs_Selected] = React.useState(() => isSelected)

    // FORM LABEL HEADING 
    const FormHeading = ({ title }) => (
        <h6 className={`text-xs lg:text-[15px] ${themeColors.headings.primary}`}>{title}</h6>
    )

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0',
        // maxWidth: '30vw'
    };

    useEffect(() => {
        setHeight(textDiv?.current?.offsetHeight)
    }, [textDiv?.current])


    return (
        <>
            <Modal
                title={<h1>Add New Team</h1>}
                centered
                open={open}
                onOk={() => {
                    setOpen(false);
                    setIsSelected(false)
                }}
                onCancel={() => {
                    setOpen(false);
                    setIsSelected(false)
                }}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div onClick={() => {
                        setIsSelected(false);
                        setIsSelected(false)
                    }} className={`w-full flex items-center justify-between pt-6 `}>
                        <Button onClick={() => {
                            setLogoUrl(formData?.logo)
                            setOpen(false);
                        }}
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => createTeam()}
                            // style={{ backgroundColor: themeColors.bg.inviteButton }} 
                            size='middle'
                            type="primary"
                            loading={isLoading}
                            disabled={!(formData?.name?.length > 0 && nameRegex.test(formData?.name))}
                        >
                            Add
                        </Button>
                    </div>
                }
                header={null}
                width={800}
            >
                <div className='w-full flex relative h-full items-start lg:items-start gap-3  py-6 '>
                    {/* LOGO FIELD  */}

                    <div
                        style={{ height: height }}
                        className='w-1/4  flex-1 p-1'>
                        <UploadButton
                            icon={<PlusOutlined style={{ color: '#1677FF' }} />}
                            title='Company Logo'
                            image={logoUrl}
                            setImage={setLogoUrl}
                        />
                    </div>
                    <div ref={textDiv} className='w-3/4  flex flex-col gap-3'>
                        {/* NAME FIELD  */}
                        <div className='w-full  flex flex-col items-start gap-2'>
                            <FormHeading title='Name' />
                            <div className='w-full'>
                                <Input
                                    size='large'
                                    style={inputStyle}
                                    placeholder="Team Name"
                                    value={formData?.name}
                                    maxLength={teamNameLength}  
                                    onChange={(e) => {
                                        setFormData({
                                            ...formData,
                                            name: e?.target?.value
                                        })
                                    }}
                                />
                            </div>  
                        </div>

                        {/* COUNTRY FIELD  */}
                        <div className='w-full  flex flex-col items-start gap-2'>
                            <FormHeading title='Country' />
                            <div className='w-full'>
                                <CountrySelectComponent
                                    selectedCountry={selectedCountry}
                                    setSelectedCountry={setSelectedCountry}
                                    isSelected={_isSelected}
                                    setIsSelected={setIs_Selected}
                                    profile={true}
                                />
                            </div>
                        </div>
                    </div>
                    {_isSelected &&
                        <div
                            onClick={() => {
                                setIs_Selected(false)
                                setIsSelected(false)
                            }}
                            className='w-full  bg-transparent h-full absolute  z-0 '>
                        </div>
                    }
                </div>
            </Modal >
        </>
    );
};
export default AddTeamDialogue;