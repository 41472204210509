import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '../Themes/ThemeProvider';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { useDispatch } from 'react-redux';
import { removeAllSigners, updateContent, updateIsWriting, updateStatus, updateTitle } from '../store/Reducers/contentReducer';
import { get} from '../Services/api-services';

const ConfirmLogout = ({ open, setOpen }) => {
    const { themeColors } = useTheme();
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch();
    const [authState, setAuthState] = useRecoilState(userAuthState) 
    const [isLoading, setIsLoading] = React.useState(false)

    // console.log(location?.pathname?.split('/')?.length);
    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };

    const handleLogout = async () => {  
        try {
            const response = await get('user/logout')
            if (response.statusCode == 200) {
                setIsLoading(true)
                setAuthState('')
                dispatch(updateContent(''))
                dispatch(updateTitle(''))
                dispatch(updateIsWriting(''))
                dispatch(removeAllSigners([]))
                dispatch(updateStatus(''))
                navigate('/', { replace: true })
                setIsLoading(false) 
            }   
        } catch (e) {
            console.log(`ERROR WHILE LOGOUT ${e}`);
        }
    }

    return (
        <>
            <Modal
                title={<h1>Are you sure you want to log out?</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div className='w-full flex items-center justify-end gap-4 pt-6'>
                        <Button
                            onClick={() => setOpen(false)}
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleLogout}
                            style={{ backgroundColor: themeColors.bg.inviteButton }}
                            size='middle'
                            type="primary"
                            loading={isLoading}
                        >
                            Logout
                        </Button>
                    </div>
                }
                header={null}
                width={500}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    <h1>Confirm logging out of your current session. You'll need to log in again to continue accessing Signizy.</h1>
                </div>
            </Modal>
        </>
    );
};
export default ConfirmLogout;