import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';
import CountrySelectComponent from './CountrySelectComponent';
import { post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import UploadButton from './UploadButton';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const inputStyle = { fontSize: '14px' }

const EditUserTeamDialogue = ({ open, setOpen, updateUser, onCancelEdit, image, setImage, selectedCompanyCountry, logoUrl, setLogoUrl, setSelectedCompanyCountry, isCompanySelected, setIsCompanySelected, userTeamDetails, setUerTeamDetails, addUser }) => {
    const { themeColors } = useTheme();
    const [height, setHeight] = React.useState('')
    const textDiv = React.useRef();

    // FORM LABEL HEADING 
    const FormHeading = ({ title }) => (
        <h6 className={`text-xs lg:text-[15px] ${themeColors.headings.primary}`}>{title}</h6>
    )

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0',
        // maxWidth: '30vw'
    };


    React.useEffect(() => {
        setHeight(textDiv?.current?.offsetHeight)
    }, [textDiv?.current])


    return (
        <>
            <Modal
                title={<h1>Edit Team Details</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => onCancelEdit()}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div className={`w-full flex items-center justify-between pt-6 `}>
                        <Button
                            onClick={() => {
                                setLogoUrl(userTeamDetails?.logo)
                                setImage('')
                                onCancelEdit();
                            }}  
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Back
                        </Button>
                        <Button onClick={() => updateUser()} style={{ backgroundColor: themeColors.bg.inviteButton }} size='middle' type="primary">Save</Button>
                    </div>
                }
                header={null}
                width={800}
            >
                <div className='w-full flex  flex-1  items-start lg:items-start gap-3  py-6 '>
                    {/* LOGO FIELD  */}
                    <div
                        style={{ height: height }}
                        className='w-1/4  flex-1 h-32  flex flex-col items-start '>
                        <UploadButton
                            icon={<PlusOutlined style={{ color: '#1677FF' }} />}
                            title='Company Logo'
                            image={image}
                            setImage={setImage}
                            logoUrl={logoUrl}
                        />
                    </div>  
                    <div ref={textDiv} className='w-3/4 flex flex-col gap-3'>
                        {/* NAME FIELD  */}
                        <div className='w-full  flex flex-col items-start gap-2'>
                            <FormHeading title='Name' />
                            <div className='w-full'>
                                <Input
                                    size='large'
                                    style={inputStyle}
                                    placeholder="Team Name"
                                    value={userTeamDetails?.name}
                                    onChange={(e) => {
                                        setUerTeamDetails({
                                            ...userTeamDetails,
                                            name: e?.target?.value
                                        })
                                    }}
                                />  
                            </div>
                        </div>

                        {/* COUNTRY FIELD  */}
                        <div className='w-full  hidden flex-col items-start gap-2'>
                            <FormHeading title='Country' />
                            <div className='w-full'>
                                {/* <CountrySelectComponent
                                    selectedCountry={selectedCompanyCountry}
                                    setSelectedCountry={setSelectedCompanyCountry}
                                    isSelected={isCompanySelected}
                                    setIsSelected={setIsCompanySelected}
                                    profile={true}
                                />   */}
                            </div>
                        </div>
                    </div>
                    {isCompanySelected &&
                        <div
                            onClick={() => {
                                setIsCompanySelected(false)
                            }}
                            className='w-full  bg-transparent h-full absolute  z-0 '>
                        </div>
                    }
                </div>
            </Modal >
        </>
    );
};
export default EditUserTeamDialogue;