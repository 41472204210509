import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';
import CountrySelectComponent from './CountrySelectComponent';
import { post } from '../Services/api-services';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { emailRegex } from '../ValidationRegex';

const { Option } = Select;

const inputStyle = { fontSize: '14px' }
const AddUser = ({ open, setOpen,isLoading, setUserData, userData, addUser }) => {
    const { themeColors } = useTheme();

    // FORM LABEL HEADING 
    const FormHeading = ({ title }) => (
        <h6 className={`text-xs lg:text-[15px] ${themeColors.headings.primary}`}>{title}</h6>
    )

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0',
        // maxWidth: '30vw'
    };      

    return (
        <>
            <Modal
                title={<h1>Add User</h1>}
                centered
                open={open}
                onOk={() => {
                    setOpen(false);
                    setUserData({
                        email: '',
                        role: 'admin'
                    })
                }}
                onCancel={() => {
                    setOpen(false);
                    setUserData({
                        email: '',
                        role: 'admin'
                    })
                }}
                closable={false}
                style={modalContentStyle}
                footer={
                    <div className={`w-full flex items-center justify-between pt-6 `}>
                        <Button
                            onClick={() => {
                                setOpen(false);
                                setUserData({
                                    email: '',
                                    role: 'admin'
                                })
                            }}
                            size='middle'
                            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
                            type="primary"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => addUser()}
                            // style={{ backgroundColor: themeColors.bg.inviteButton }}
                            size='middle'
                            type="primary"
                            disabled={!(userData?.email?.length > 0 && emailRegex.test(userData?.email) && userData?.role)}
                            loading={isLoading}
                        >
                            Invite  
                        </Button>   
                    </div>
                }
                header={null}
                width={800}
            >
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>

                    {/* EMAIL FIELD  */}
                    <div className='w-full  flex flex-col items-start gap-2'>
                        <FormHeading title='Email' />
                        <div className='w-full'>
                            <Input
                                size='large'
                                style={inputStyle}
                                placeholder="a@a.com"
                                value={userData?.email}
                                type='email'
                                prefix={<EmmailIcon color={themeColors.icons.secondary} />}
                                onChange={(e) => {
                                    setUserData({
                                        ...userData,
                                        email: e?.target?.value
                                    })
                                }}
                                status={userData?.email?.length > 0 && !emailRegex.test(userData?.email) ? 'error' : ''}
                            />
                        </div>
                    </div>
                    {/* ROLE FIELD  */}
                    <div className='w-full  flex flex-col items-start gap-2'>
                        <FormHeading title='Role' />    
                        {/* <div className='w-full'>
                            <Input
                                size='large'
                                style={inputStyle}
                                placeholder="Sub Admin"
                                prefix={<RoleIcon color={themeColors.icons.secondary} />}
                                onChange={(e) => {
                                    setUserData({
                                        ...userData,
                                        role: e?.target?.value
                                    })
                                }}
                            />
                        </div> */}
                        <div className='w-full'>
                            <Select
                                // defaultValue="admin"
                                value={userData?.role}
                                style={{
                                    width: '100%',
                                }}
                                onChange={(value) => {
                                    setUserData({
                                        ...userData,    
                                        role: value 
                                    })
                                }}
                                options={[
                                    {
                                        value: 'admin',
                                        label: 'Admin',
                                    },
                                    {
                                        value: 'moderator', 
                                        label: 'Moderator', 
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    );
};
export default AddUser;