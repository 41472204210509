import { Collapse, theme } from 'antd'
import React from 'react'
import { useTheme } from '../Themes/ThemeProvider'
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { DocumentIcon } from './Icons';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from 'pdfjs-dist/build/pdf.worker.entry';
import { BASEURL } from '../Services/api-services';

const ShowDocumentList = ({ documents }) => {
    const { themeColors } = useTheme();


    const customExpandIcon = ({ isActive }) => {
        return isActive ? <UpOutlined style={{ color: '#1677FF' }} /> : <DownOutlined style={{ color: '#1677FF' }} />;
    };


    const accordianStyle = {
        backgroundColor: themeColors.bg.accordian,
        borderRadius: 0,
        border: `1px solid  ${themeColors.borders.accordianDoc}`,
        width: '100%',
        padding: 0
    }

    return (
        <>
            < div className='py-2    w-full flex flex-col items-start gap-2' >
                {documents?.map((document, idx) => {
                    return (
                        <Collapse
                            key={idx}
                            style={accordianStyle}
                            bordered={false}
                            expandIconPosition='end'
                            collapsible="header"
                            // defaultActiveKey={['1']}
                            expandIcon={customExpandIcon}
                            items={[
                                {
                                    key: '1',
                                    label: (
                                        <div className='flex items-center justify-between w-full '>
                                            <div className='flex items-center gap-3'>
                                                <DocumentIcon color={themeColors.icons.third} />
                                                <h6 className={`text-sm lg:text-base ${themeColors.text.secondary}`}>{document?.name}</h6>
                                            </div>
                                            {/* <CloseOutlined style={{ color: theme.palette.icons.user }} /> */}
                                        </div>
                                    ),
                                    children: (
                                        <div className='w-full'>
                                            <Viewer fileUrl={`${BASEURL}public/documents/${document?.fileName}`} />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    )
                })}
            </div >
        </>
    )
}

export default ShowDocumentList
