import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { EmmailIcon, LocationIcon, PeopleIcon, RoleIcon } from './Icons';
import { useTheme } from '../Themes/ThemeProvider';


const RemoveUserDialogue = ({ open, setOpen, userData, setUserData, isLoading, removeUser }) => {
    const { themeColors } = useTheme();

    const modalContentStyle = {
        // backgroundColor: 'red',
        padding: '0'
    };

    return (
        <>
            <Modal
                title={<h1>Do you want to remove {userData?.user?.name}?</h1>}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                style={modalContentStyle}
                closable={false}
                footer={
                    <div className='w-full flex items-center justify-end gap-4 pt-6'>
                        <Button onClick={() => setOpen(false)} size='middle' style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }} type="primary">Cancel</Button>
                        <Button
                            onClick={removeUser}
                            size='middle'
                            type="primary"
                            loading={isLoading}
                        >
                            Remove
                        </Button>
                    </div>
                }
                header={null}
                width={500}
            >   
                <div className='w-full flex flex-col lg:flex-row items-start lg:items-start gap-3 lg:gap-6 py-6 flex-wrap'>
                    <h1>Confirm removing {userData?.user?.name} from the list. This action cannot be undone.</h1>
                </div>
            </Modal>
        </>
    );
};
export default RemoveUserDialogue;