import React, { useEffect } from 'react'
import { post } from '../Services/api-services';
import { Alert, Button, message } from 'antd';
import VerifyEmailComponent from './VerifyEmailComponent';
import VerifyPhoneComponent from './VerifyPhoneComponent';

const VerifyEmail = ({ authState, currentUser, setAuthState, getCurrentUserDetails }) => {
    const [open, setOpen] = React.useState(false)
    const [openPhone, setOpenPhone] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [mobile, setMobile] = React.useState('')
    const [otp, setOtp] = React.useState(false)
    const [count, setCount] = React.useState(30);

    useEffect(() => {
        setEmail(currentUser?.email)
        setMobile(currentUser?.mobile)
    }, [currentUser])

    // SEND OTP ON EMAIL/MOBILE 
    const sendOtp = async () => {   
        try {   
            const _body = {
                email: email
            }
            const response = await post(`user/${authState?.id}/verifyEmail`, _body)
            if (response?.statusCode == 200) {
                message.success(response?.data?.message)    
                startCounter();
            } else {
                message.error(response?.message?.message)
            }

        } catch (e) {
            // message.error(e)    
            console.log(`ERROR WHILE SENDING OTP ${e}`);
        }
    }

    // VERIFY OTP 
    const verifyOtp = async () => {
        try {
            const _body = {
                otp: parseInt(otp),
                value: email,
                type: 'email'
            }
            // setOpenPhone(true)
            // setOpen(false)
            const response = await post(`user/${authState?.id}/verifyOtp`, _body)
            if (response?.statusCode == 200) {
                message.success('Email verified')
                setOpen(false)
                setOtp('')
                setAuthState();
                getCurrentUserDetails();
            } else {
                message.error(response?.message)
            }
        } catch (e) {
            message.error(e)
            console.log(`ERROR WHILE VERIFYING OTP ${e}`);
        }
    }

    // START COUNTER 
    const startCounter = () => {
        const intervalId = setInterval(() => {
            // Decrease count by 1 every second
            setCount(prevCount => prevCount - 1);
        }, 1000); // Interval of 1000 milliseconds (1 second)

        // Stop the counter after 30 seconds
        setTimeout(() => {
            clearInterval(intervalId); // Clear the interval
            setCount(30); // Reset count to 30 seconds
        }, 30000); // 30 seconds in milliseconds
    };

    return (
        <>
            {authState && !authState?.emailVerify ?
                <Alert
                    style={{ width: '100%', textAlign: 'center', fontSize: '12px', padding: '5px' }}
                    showIcon={false}
                    type="error"
                    message={
                        <span>
                            Your email verification is pending{' '}
                            <Button
                                style={{ padding: 0 }}
                                type='link'
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                click here
                            </Button>
                            {''} to verify.
                        </span>
                    }
                    banner
                />
                : null}
            <VerifyEmailComponent
                otp={otp}
                open={open}
                email={email}
                count={count}
                setOtp={setOtp}
                setOpen={setOpen}
                onVerifyOtp={verifyOtp}
                onSendOtp={() => sendOtp()}
            />

        </>
    )
}

export default VerifyEmail
