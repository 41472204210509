import { createSlice } from "@reduxjs/toolkit";

// Reducer.js
const initialState = {
    snackbar: '',
}

export const Reducer = createSlice({
    name: 'home',
    initialState,
    reducers: {
        getStatus: (state, action) => {
            state.snackbar = action.payload
        },
        updateSnackbar: (state, action) => {
            state.snackbar = action.payload
        },
    },
})


export const { updateSnackbar, getStatus } = Reducer.actions

export default Reducer.reducer  