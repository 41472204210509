import React, { useState } from 'react';
import { Button, Input, Select, AutoComplete, message, Tooltip } from 'antd';
import { DrawIcon, EmailOptionIcon, EmailSmallIcon, EmmailIcon, MobileIcon, MobileOptionIcon, PeopleIcon, RightIcon, TitleIcon } from './Icons';
import { CloseOutlined, CrownOutlined, PlusOutlined } from '@ant-design/icons';
import { useDimensions } from '../DimensionContext/DimensionContext';
import CountrySelectComponent from './CountrySelectComponent';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';
import { emailLength, emailRegex, mobileRegex, nameLength, titleLength } from '../ValidationRegex';
import { formatDateFunction, get, post } from '../Services/api-services';
import { useTheme } from '../Themes/ThemeProvider';
import { H3, H5 } from '../Themes/StylingComponent';
import SignaturePadComponent from './SignaturePadComponent';
import MobileCountryComponent from './MobileCountryComponent';
import SignerComponent from './SignerComponent';

const { Option } = AutoComplete;

const AddSIgnForm = ({ signersData, isSigned, saveDocument, getCurrentUserDetails, content2Ref, getDocumentData, currentUser, _documentId, selectedCountry, methods, setSelectedCountry, setSignerData, addSigner, signers, removeSigner }) => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions();
    const [isSelected, setIsSelected] = React.useState(false)
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [openSignPad, setOpenSignPad] = React.useState(false)
    const [currentSigner, setCurrentSigner] = React.useState('')
    const [svg, setSvg] = React.useState('')
    const [isAdd, setIsAdd] = React.useState(false)
    const [_methods, set_methods] = React.useState({
        draw: true,
        email: true,
        mobile: true
    })

    const buttonStyle = {
        backgroundColor: themeColors.bg.addButton,
        padding: lg || xl || xxl ? '22px' : '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

    React.useEffect(() => {
        if (signersData?.title?.length > 0 && signersData?.name?.length > 0 && emailRegex.test(signersData?.email)) {
            if (methods?.mobile) {
                if (signersData?.mobile?.length > 0 && (signersData?.mobile?.length === selectedCountry?.phoneLength || (!mobileRegex?.test(signersData?.mobile) && signersData?.mobile?.length > 0))) {
                    setIsAdd(true)
                } else {
                    setIsAdd(false)
                }
            } else {
                setIsAdd(true)
            }
        } else {
            setIsAdd(false)
        }
    }, [signersData, methods, signersData?.mobile, selectedCountry])

    const inputStyle = { fontSize: lg || xl || xxl ? '14px' : '14px', width: '100%', }

    // FORM LABEL HEADING 
    const FormHeading = ({ title }) => (
        <H5
            title={title}
        />
        // <h6 className={`text-sm lg:text-base ${themeColors.headings.primary}`}>{title}</h6>
    )

    // UPDATE MOBILE 
    const updateData = (e) => {
        setSignerData({
            ...signersData,
            mobile: e?.target?.value
        });
    }

    // SET COUNTRY DATA 
    React.useEffect(() => {
        setCountryData()
    }, [selectedCountry])

    const setCountryData = () => {
        setSignerData({
            ...signersData,
            country: selectedCountry,
        })
    }

    // SELECT SIGNER 
    const handleSelect = async (selectedData) => {
        try {

            setSignerData({
                ...setSignerData,
                title: signersData?.title,
                name: selectedData?.name,
                email: selectedData?.email,
                mobile: selectedData?.mobile,
            })
            setSelectedCountry(selectedData?.country)
        } catch (e) {
            console.log(`ERROR WHILE FETCHING DETAILS ${e}`);
        }
    };

    // AUTOCOMPLETE OPTIONS 
    const [templateOpt, setTempOptions] = React.useState([]);

    const handleSearch = async (value) => {
        try {
            const _data = {
                teamId: authState?.teamId,
                name: value
            };
            const response = await post(`signer/getSigner`, _data);
            if (response.statusCode === 200) {
                setTempOptions(response.data?.map((sin, index) => {
                    return {
                        label: <div onClick={() => handleSelect(sin)} className='border-b border-[#D9D9D9] py-2 flex flex-col items-start gap-1'>
                            <h1 className='text-[#424D51] capitalize font-semibold'>{sin.name}</h1>
                            <p className='flex items-center gap-2'><EmailSmallIcon color={themeColors.icons.secondary} /> {sin.email}</p>
                            {sin?.mobile &&
                                <p className='flex items-center gap-2'><MobileIcon color={themeColors.icons.secondary} /> +{sin?.country?.phone} {sin.mobile}</p>
                            }
                        </div>
                    }
                }))
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING SUGGESTIONS ${e}`);
        }
    };

    // ADD MY SINGNATURE FUNTION  
    const addMySign = async (svgData) => {
        try {
            await saveDocument()
            let _body = {
                documentId: _documentId,
                title: 'sender',
                // title: '',
                svg: svgData
            }

            if (currentUser?.svg) {
                delete _body.svg
            }

            const response = await post(`user/${currentUser?._id}/AddMySign`, _body)
            if (response?.statusCode == 200) {
                message?.success('sign added succesfully!')
                setOpenSignPad(false)
                // getDocumentData();   
                getCurrentUserDetails()
            } else {
                message.error(response?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE ADDIGN MY SIGN ${e}`);
        }
    }

    return (
        <>
            <div className='w-full flex flex-col  items-start lg:items-start gap-3 lg:gap-6  '>
                <div className='w-full px-3 flex flex-col  items-start  gap-5 lg:gap-6'>
                    <div className='py-2 w-full lg:w-fit flex items-center gap-2 pt-4'>
                        <H3
                            title='SIGNERS'
                        />
                        {/* <h2 style={{ color: themeColors.headings.primary }} className='text-base lg:text-xl font-semibold'>SIGNERS</h2> */}
                        {!isSigned ?
                            <Button
                                style={{ color: themeColors.icons.primary }}
                                icon={<PlusOutlined style={{ color: themeColors.icons.primary, fontSize: '18px' }} />}
                                type="link"
                                onClick={() => {
                                    if (!currentUser?.svg) {
                                        setOpenSignPad(true)
                                    } else {
                                        addMySign();
                                    }
                                }}
                            >
                                Add my signature
                            </Button>
                            : null}
                    </div>
                    <div className='w-full px-3 flex flex-col lg:flex-row items-end gap-5 lg:gap-6'>
                        {/* TITLE FIELD  */}
                        <div className='w-full lg:w-[30%] flex flex-col items-start gap-3'>
                            <FormHeading title='Title *' />
                            <div className='w-full'>
                                <Input
                                    size='large'
                                    style={inputStyle}
                                    placeholder="Title"
                                    maxLength={titleLength}
                                    // disabled={isSigned}
                                    prefix={<TitleIcon color={themeColors.icons.secondary} />}
                                    value={signersData?.title}
                                    onChange={(e) => {
                                        setSignerData({
                                            ...signersData,
                                            title: e.target.value
                                        });
                                    }}
                                />  
                            </div>
                        </div>
                        {/* NAME FIELD  */}
                        <div className='w-full lg:w-[30%] flex flex-col items-start gap-3'>
                            <FormHeading title='Name *' />
                            <div className='w-full '>
                                <AutoComplete
                                    style={{
                                        width: '100%',
                                        background: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        border: '1px solid #d9d9d9',
                                        borderRadius: '8px',
                                    }}
                                    // disabled={isSigned}  
                                    size='large'
                                    onSearch={handleSearch}
                                    prefix={<PeopleIcon color={themeColors.icons.secondary} />}
                                    children={
                                        <PeopleIcon color={themeColors.icons.secondary} />
                                    }
                                    options={templateOpt}
                                    onChange={() => console.log('heyy')}
                                    value={signersData?.name}
                                >
                                    {/* {templateOpt?.map((signer, index) => (
                                        <Option key={index}>{`${signer?.name} (${signer?.email})`}
                                        </Option>
                                    ))} */}
                                    <Input
                                        // style={inputStyle}
                                        style={{ border: 'none', width: '100%', fontSize: lg || xl || xxl ? '14px' : '14px', }}
                                        placeholder='Name *'
                                        onChange={(e) => {
                                            setSignerData({
                                                ...signersData,
                                                name: e.target.value
                                            });
                                        }}
                                        prefix={
                                            <PeopleIcon color={themeColors.icons.secondary} />
                                        }
                                        maxLength={24}
                                    />
                                </AutoComplete>
                            </div>
                        </div>
                        {/* EMAIL FIELD  */}
                        <div className='w-full lg:w-[30%] flex flex-col items-start gap-3'>
                            <FormHeading title='Email *' />
                            <div className='w-full'>
                                <Input
                                    size='large'
                                    style={inputStyle}
                                    placeholder="a@a.com"
                                    maxLength={emailLength}
                                    // disabled={isSigned}
                                    prefix={<EmmailIcon color={themeColors.icons.secondary} />}
                                    status={signersData?.email?.length > 0 && !emailRegex?.test(signersData?.email) ? 'error' : ''}
                                    value={signersData?.email}
                                    onChange={(e) => {
                                        setSignerData({
                                            ...signersData,
                                            email: e.target.value
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        {/* PHONE NUMBER FIELD  */}
                        <div className='w-full lg:w-[30%] flex relative flex-col items-start gap-3'>
                            <FormHeading title={`Phone Number ${methods?.mobile ? '*' : ''}`} />
                            {/* <div className='w-full'>
                                <Input style={inputStyle} size='large' addonBefore={selectBefore} defaultValue="Docs" />
                            </div> */}
                            <MobileCountryComponent
                                mobile={signersData?.mobile}
                                updateData={(e) => updateData(e)}
                                selectedCountry={selectedCountry}
                                setSelectedCountry={setSelectedCountry}
                                isSelected={isSelected}
                                setIsSelected={setIsSelected}
                                methods={_methods}
                            />
                        </div>
                        <div
                            className='w-full lg:w-fit  h-full flex items-end  justify-center lg:justify-end  '>
                            <Button
                                // style={signersData?.title?.length > 0 && signersData?.name?.length > 0 && emailRegex.test(signersData?.email) ? buttonStyle : ''}
                                // disabled={signersData?.title?.length > 0 && signersData?.name?.length > 0 && emailRegex.test(signersData?.email) ? false : true}
                                style={isAdd ? buttonStyle : ''}
                                disabled={!isAdd}
                                onClick={addSigner}
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined style={{ color: themeColors.icons.primary, fontSize: lg || xl || xxl ? '22px' : '28px' }} />}
                                size={'large'}
                            />
                        </div>
                    </div>
                </div>
                <div ref={content2Ref} className={`w-full px-3 border-t ${themeColors.borders.third}`}>
                    {signers?.map((signer, index) => {
                        return (
                            <div key={index} className='w-full flex items-center justify-between py-3 group mb-2'>
                                <div className='flex items-start gap-2'>
                                    <div className='mt-2'>
                                        {signer?.status &&
                                            <RightIcon />
                                        }
                                    </div>
                                    <div className='flex flex-col items-start gap-1'>
                                        <Tooltip title={signer?.name?.length > 20 ? signer?.name : ''}>
                                            <h3 className={`text-base capitalize lg:text-base font-medium ${themeColors.headings.primary}`}>
                                                {signer?.name.length > 20 ? signer?.name.substring(0, 20) + '...' : signer?.name}
                                            </h3>
                                        </Tooltip>
                                        <div className='flex flex-col items-start lg:items-center lg:flex-row gap-1'>
                                            <div className='flex  gap-1'>
                                                {/* <p className={`text-xs  whitespace-nowrap  lg:text-sm ${themeColors.text.fourth}`}>Title: </p> */}
                                                <Tooltip title={signer?.title?.length > 20 ? signer?.title : ''}>
                                                    <p className={`text-xs whitespace-nowrap lg:text-sm ${themeColors.text.fourth}`}>
                                                        {signer?.title.length > 20 ? signer?.title.substring(0, 20) + '...' : signer?.title}
                                                    </p>
                                                </Tooltip>
                                            </div>
                                            <span className='w-1 hidden lg:block h-1 bg-[#8E8E8E] rounded-full mr-1'></span>
                                            <Tooltip title={signer?.email?.length > 20 ? signer?.email : ''}>
                                                <p className={`text-xs whitespace-nowrap lg:text-sm ${themeColors.text.fourth}`}>
                                                    {signer?.email.length > 20 ? signer?.email.substring(0, 20) + '...' : signer?.email}
                                                </p>
                                            </Tooltip>
                                            {signer?.mobile &&
                                                <>
                                                    <span className='w-1 hidden lg:block h-1 bg-[#8E8E8E] rounded-full mr-1'></span>
                                                    <p className={`text-xs whitespace-nowrap lg:text-sm ${themeColors.text.fourth}`}>+{signer?.country?.phone} {signer?.mobile}</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {!signer?.status ?
                                    <div className='opacity-0 group-hover:opacity-100 cursor-pointer'>
                                        <Button
                                            onClick={() => removeSigner(index)}
                                            shape='circle'
                                            icon={<CloseOutlined style={{ color: themeColors.icons.secondary }} />}
                                            type="text"
                                        >
                                        </Button>
                                    </div>
                                    :
                                    <div className='flex items-center w-1/5 gap-2'>
                                        {
                                            signer?.type === 'email' ? <EmailOptionIcon /> :
                                                signer?.type === 'mobile' ? <MobileOptionIcon /> :
                                                    <DrawIcon />
                                        }
                                        <div>
                                            <h6
                                                className={`text-xs  ${themeColors.headings.primary}`}
                                            >
                                                {
                                                    signer?.type === 'email' ? signer?.email :
                                                        signer?.type === 'mobile' ? `+${signer?.country?.phone} ${signer?.mobile}` :
                                                            // <div className='w-full h-full'>
                                                            //     <img
                                                            //         className='w-full h-full object-contain'
                                                            //         src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(signer.svg)}`}
                                                            //         alt="SVG Image"
                                                            //     />
                                                            // </div>
                                                            <div className='w-16 h-14  flex items-center justify-center '>
                                                                <img className='h-full w-full object-contain' src={`data:image/svg+xml;utf8,${encodeURIComponent(signer?.svg)}`} alt="SVG Image" />
                                                            </div>
                                                    // <div className='w-16 h-14  flex items-center justify-center '>
                                                    // <img className='w-full h-full object-contain' src={`data:image/svg+xml;utf8,${encodeURIComponent(signer?.svg)}`} alt="SVG Image" />
                                                    // </div>
                                                }
                                            </h6>
                                            <p
                                                className={`text-[10px] whitespace-nowrap  ${themeColors.text.fourth}`}
                                            >
                                                {formatDateFunction(signer?.date)}
                                            </p>
                                        </div>
                                    </div>
                                }
                            </div>
                            // <SignerComponent
                            //     key={index}
                            //     name={signer?.name} 
                            //     title={signer?.title}   
                            //     sign={signer}
                            //     currentSigner={currentSigner}
                            // />     
                        )
                    })}
                </div>
            </div>
            {isSelected &&
                <div onClick={() => setIsSelected(false)} className='w-full h-screen bg-transparent  fixed top-0 left-0 z-60'>
                </div>
            }

            {/* SIGNTURE PAD COMPONENT  */}
            {openSignPad ?
                <SignaturePadComponent
                    open={openSignPad}
                    _documentId={_documentId}
                    setOpen={setOpenSignPad}
                    currentEmail={currentUser?.email}
                    currentSigner={currentSigner}
                    setCurrentSigner={setCurrentSigner}
                    currentUser={currentUser}
                    addMySign={(svg) => addMySign(svg)}
                    onclose={() => {
                        setOpenSignPad(false)
                    }}
                />
                : null}
        </>
    )
}

export default AddSIgnForm
