// SettingDrawer.js
import React from 'react'
import { BillingIcon, HelpIcon, LeftArrowIcon, LogoutIcon, PeopleIcon, UserIcon } from './Icons';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmLogout from './ConfirmLogout';
import { useTheme } from '../Themes/ThemeProvider';
import { useRecoilState } from 'recoil';
import { userAuthState } from '../Services/RecoilService';


const SettingDrawer = () => {
    const { themeColors } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const url = '/settings'
    const [open, setOpen] = React.useState(false)
    const [authState, setAuthState] = useRecoilState(userAuthState)
    let [filteredOptions, setFilteredOptions] = React.useState([]);
    const _route = location?.pathname?.split('/')[2];
    // DRAWER OPTIONS 

    React.useEffect(() => {
        const options = [
            {
                label: 'Profile',
                icon: PeopleIcon,
                route: _route ? 'profile' : ''
            },
            {
                label: 'Users',
                icon: UserIcon,
                route: 'user'
            },
            {
                label: 'Teams',
                icon: UserIcon,
                route: 'manage-team'
            },
            {
                label: 'Billing',
                icon: BillingIcon,
                route: 'billing'
            },
            {
                label: 'Help & Support',
                icon: HelpIcon,
                route: 'help'
            },
            {
                label: 'Logout',
                icon: LogoutIcon,
                route: 'logout'
            },
        ];

        // Filter out the 'Billing' option if the current user's role is 'moderator'
        const newFilteredOptions = authState?.role === 'moderator'
            ? options.filter(option => option.route !== 'billing')
            : options;

        setFilteredOptions(newFilteredOptions);
    }, [authState,location?.pathname]);    

    const handleClick = (el) => {   
        if (el?.route === 'logout') {   
            setOpen(true)
        } else {
            console.log(el?.route);
            if (el?.route) {
                navigate(`${url}/${el?.route}`)
            } else {
                navigate(`${url}/profile`)
            }
        }
    }   

    return (
        <>
            <div className={`w-full shadow h-full  flex flex-col flex-1 ${themeColors.bg.primary}`}>
                <div className='w-full flex flex-1 '>
                    <div className='w-full flex flex-col  border-r border-r-[#c9c3c340] px-3 gap-0 lg:gap-1 py-4 lg:pt-6'>
                        {filteredOptions?.map((el, idx) => {
                            const iconColor = _route == el?.route ? themeColors.icons.seven : themeColors.icons.sixth;
                            return (
                                <div onClick={() => handleClick(el)} key={idx} className={`cursor-pointer rounded p-3 my-2 flex items-center gap-4 w-full ${_route == el?.route ? themeColors?.bg?.selecttButton : ''}`}>
                                    <el.icon color={iconColor} />
                                    <h6 className={`text-lg lg:text-base font-normal tracking-wide ${_route == el?.route ? themeColors.headings.third : themeColors.headings.primary}`}>{el.label}</h6>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <ConfirmLogout
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}

export default SettingDrawer;

