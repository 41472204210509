import { useDimensions } from "../DimensionContext/DimensionContext";
import SettingDrawer from '../Components/SettingDrawer';
import UserListRow from "../Components/UserListRow";
import { Button, Skeleton, message, } from 'antd';
import React, { useEffect } from 'react'
import AddUser from "../Components/AddUser";
import RemoveUserDialogue from '../Components/RemoveUserDialogue'
import ChangeRole from '../Components/ChangeRole'
import Header from "../Components/Header";
import { useTheme } from "../Themes/ThemeProvider";
import { get, post } from '../Services/api-services'
import { userAuthState } from "../Services/RecoilService";
import { useRecoilState } from "recoil";
import { DocumentSkaleton, UsersSkaleton } from "./DocumentSkaleton";

const _userData = {
    email: '',
    role: 'admin',
    teamId: ''
}

const ManageUser = () => {
    const { themeColors } = useTheme();
    const { lg, xl, xxl } = useDimensions()
    const parentDiv = React.useRef(null);
    const childDiv = React.useRef(null);
    const [height, setHeight] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [openRemoveUser, setOpenRemoveUser] = React.useState(false);
    const [openChangeRole, setOpenChangeRole] = React.useState(false);
    const [allUsers, setAllUsers] = React.useState([])
    const [authState, setAuthState] = useRecoilState(userAuthState)
    const [userData, setUserData] = React.useState({ ..._userData })
    const [selectedUserData, setSelectedUserData] = React.useState('')
    const [role, setRole] = React.useState('')
    const [currentTeamData, setCurrentTeamData] = React.useState('')
    const [dataLoading, setDataLoading] = React.useState(true)
    const [isLoading, setisLoading] = React.useState(false)
    const [changeRoleLoading, setChangeRoleLoading] = React.useState(false)
    const [removeUserLoading, setRemoveUserLoading] = React.useState(false)

    // Define the number of DocumentRows to render
    const numberOfRows = 7;

    useEffect(() => {
        getAllUsers();
        getTeamData();
    }, [])

    const getAllUsers = async () => {
        try {
            const response = await get(`team/${authState?.teamId}/user`)
            if (response?.statusCode == 200) {
                setAllUsers(response?.data)
                setDataLoading(false)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING ALL USERS ${e}`);
        }
    }

    // GET CURRENT TEAM DATA 
    const getTeamData = async () => {
        try {
            const response = await get(`team/${authState?.teamId}`);
            if (response?.statusCode == 200) {
                setCurrentTeamData(response?.data)
            }
        } catch (e) {
            console.log(`ERROR WHILE FETCHING CURRENT PLAN ${e}`);
        }
    }

    // INVITE NEW USER  
    const addUser = async () => {
        try {
            setisLoading(true)
            const _body = { ...userData }
            _body.teamId = authState?.teamId

            if (_body.email && allUsers.find(user => user?.user.email === _body.email)) {
                message.error(`User already invited with this Email ${_body?.email}`);
                setisLoading(false)
                return
            }

            _body.email = _body.email?.toLowerCase();
            console.log(_body);

            // _body.country = selectedCountry; 
            const response = await post('team/inviteUser', _body)
            setisLoading(false)
            if (response?.statusCode == 200) {
                message.success('User successfully invited!')
                getAllUsers();
                setOpen(false)
                setUserData(_userData)
            } else {
                message.error(response?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE ADDING USER ${e}`);
            message.error('error while inviting user!')
        }
    }

    // CHANGE USER ROLE 
    const changeRole = async () => {
        try {
            setChangeRoleLoading(true)
            const _body = {
                userId: selectedUserData?.user?._id,
                teamId: authState?.teamId,
                role: selectedUserData?.role
            }
            const response = await post('team/changeRole', _body)
            setChangeRoleLoading(false)
            if (response.statusCode == 200) {
                getAllUsers();
                setSelectedUserData('')
                setOpenChangeRole(false)
                message?.success(response?.data?.message)
            } else {
                message.error(response?.message)
            }
        } catch (e) {
            console.log(`ERROR WHILE CHANGING ROLE ${e}`);
        }
    }

    // REMOVE USER 
    const removeUser = async () => {
        try {
            setRemoveUserLoading(true)
            const _body = {
                teamId: authState?.teamId,
                userId: selectedUserData?.user?._id
            }
            const response = await post('team/removeUser', _body)
            setRemoveUserLoading(false)
            if (response?.statusCode == 200) {
                setOpenRemoveUser(false)
                getAllUsers();
            }
        } catch (e) {
            console.log(`ERROR WHILE REMOVING USER ${e}`);
        }
    }

    return (
        <>
            <div className={`w-full h-full py-4 lg:px-6 ${themeColors.bg.primary}`}>
                <div className='w-full flex  px-3 items-center justify-between'>
                    <Header title='USERS' />
                    {authState?.role != 'moderator' ?
                        dataLoading ?
                            <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                            :
                            <Button
                                onClick={() => {
                                    if (!allUsers?.length >= currentTeamData?.plan?.numberOfUser) {
                                        message.error(`Your current active plan has reached its user limit of ${currentTeamData?.plan?.numberOfUser} users. Please consider upgrading to add more users.`)
                                    } else {
                                        setOpen(true)
                                    }
                                }}
                                type="primary"  
                            >   
                                Add User    
                            </Button>
                        : null} 
                </div>
                <div className='w-full overflow-y-auto flex-1  my-4 '>
                    <div className='w-full  py-4'>
                        {dataLoading ?
                            <UsersSkaleton />
                            : allUsers?.length > 0 ?
                                allUsers?.map((user, index) => (
                                    <UserListRow    
                                        key={index}
                                        user={user}
                                        open={openRemoveUser}
                                        setOpen={setOpenRemoveUser}
                                        changeRole={openChangeRole}
                                        setChangeRole={setOpenChangeRole}
                                        selectedUserData={selectedUserData}
                                        setSelectedUserData={setSelectedUserData}
                                    />
                                ))
                                : 'No Users Available'
                        }
                    </div>
                </div>
            </div>

            <AddUser
                open={open}
                userData={userData}
                setUserData={setUserData}
                setOpen={setOpen}
                addUser={addUser}
                isLoading={isLoading}
            />

            {/* REMOVE USER DIALOGUE  */}
            <RemoveUserDialogue
                open={openRemoveUser}
                removeUser={removeUser}
                userData={selectedUserData}
                setOpen={setOpenRemoveUser}
                setUserData={setSelectedUserData}
                isLoading={removeUserLoading}
            />

            {/* CHANGE ROLE DIALOGUE / */}
            <ChangeRole
                role={role}
                setRole={setRole}
                open={openChangeRole}
                userData={selectedUserData}
                setUserData={setSelectedUserData}
                setOpen={setOpenChangeRole}
                onChangeRole={changeRole}
                isLoading={changeRoleLoading}
                onCloseDialogue={() => {
                    setOpenChangeRole(false);
                    // getAllUsers();  \
                }}
            />

        </>
    )
}

export default ManageUser
