import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { Button, Modal, Checkbox, message } from 'antd';
import { ReloadIcon } from './Icons';
import { useDimensions } from '../DimensionContext/DimensionContext';
import { post } from '../Services/api-services';
import { useDispatch } from 'react-redux';
import { removeAllSigners } from '../store/Reducers/contentReducer';
import { useTheme } from '../Themes/ThemeProvider';

const SignaturePadComponent = ({
  open,
  setOpen,  
  setCurrentSigner,
  currentUser,
  getDocumentDetails,
  addMySign,
  currentSigner,
  _documentId,
  currentEmail,
  onclose
}) => {
  const { themeColors } = useTheme();
  const dispatch = useDispatch();
  const { lg, sm, xl, xxl, md } = useDimensions();
  const [isDisable, setIsDisable] = useState(false);  
  const canvasRef = useRef(null); 
  const containerRef = useRef(null);  
  const signaturePadRef = useRef(null); 

  useEffect(() => {
    if (canvasRef.current && containerRef.current) {
      if (!signaturePadRef.current) {
        signaturePadRef.current = new SignaturePad(canvasRef.current, {
          // Additional configuration options can go here
        });
      }
      resizeCanvas();
      window.addEventListener('resize', resizeCanvas);
      return () => {
        window.removeEventListener('resize', resizeCanvas);
      };
    }
  }, [open]); 

  // React?.useEffect(()=>{ 
  //    setIsDisable(true)
  // },[SignaturePad])

  const resizeCanvas = () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (canvas && container) {
      canvas.width = container.offsetWidth;
      canvas.height = 200; // Adjust as needed
      const signaturePad = signaturePadRef.current;
      if (signaturePad) {
        signaturePad.clear(); // Clear the previous content to fit the new size
        // setIsDisable(false)  
      } 
    } 
  };

  const addSign = async () => {
    try {
      const signaturePad = signaturePadRef.current;
      if (signaturePad.isEmpty()) {
        message.error('Sign cannot be blank!');
        return;
      }
      const svgData = signaturePad.toSVG();
      await addMySign(svgData); 
      handleClear();
      if (getDocumentDetails) {  
        getDocumentDetails();
      }
    } catch (e) {
      console.log(`ERROR WHILE ADDING MY SIGN ${e}`);
    }
  };

  const handleSave = async () => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      const svgData = signaturePad.toSVG();
      try {
        const _body = {
          type: 'draw', 
          value: svgData,
          documentId: _documentId,  
          email: currentEmail,
        };
        if (signaturePad.isEmpty()) {
          message.error('Sign cannot be blank!');
          return;
        }

        const response = await post('auth/signByDraw', _body);
        if (response?.statusCode === 200) {
          dispatch(removeAllSigners(response?.data));
          setOpen(false);
          const _currentSigner = response?.data?.find((el) => el?.email === currentEmail);
          setCurrentSigner(_currentSigner);
          message.success('Document Signed Successfully!');
          handleClear();
          if (getDocumentDetails) {
            getDocumentDetails();
          }
        }
      } catch (e) {
        console.log(`ERROR WHILE SIGNING DOCUMENT ${e}`);
        message.error('Error While Signing Document!');
      }
    } else {
      console.error('Signature pad is not initialized');
    }
  };

  const handleClear = () => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const modalContentStyle = {
    padding: '0',
  };

  const onChange = (e) => {
    setIsDisable(e.target.checked);
  };
  
  return (
    <Modal
      title={<h1></h1>}
      centered
      open={open}
      onOk={() => setOpen(false)}
      closable={false}
      style={modalContentStyle}
      footer={
        <div className='w-full flex items-center justify-between gap-4 pt-6'>
          <Button
            onClick={async () => {
              onclose();
            }}
            size='middle'
            style={{ backgroundColor: themeColors.bg.backButton, color: themeColors.bg.inviteButton }}
            type='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (currentUser) {
                addSign();
              } else {
                handleSave();
              }
            }}
            disabled={!isDisable}
            size='middle'
            type='primary'
          >
            Sign
          </Button>
        </div>
      }
      width={700}
    >
      <div ref={containerRef}>
        <div className={`rounded relative border border-dashed ${themeColors.borders.signP}`}>
          <div onClick={handleClear} className='absolute cursor-pointer right-3 top-3'>
            <ReloadIcon color={themeColors.icons.fourth} />
          </div>
          <canvas style={{ touchAction: 'none', userSelect: 'none' }} ref={canvasRef}></canvas>
        </div>
        <div className='flex items-start gap-3 py-4'>
          <Checkbox onChange={onChange} />
          <p className={`text-xs ${themeColors.text.fourth}`}>
            By clicking the button below, I acknowledge and agree that this represents my signature in a legally binding manner.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default SignaturePadComponent;
