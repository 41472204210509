import React from 'react';
import { Button, ConfigProvider, Segmented, Tabs } from 'antd';
import { DeleteIcon, SearchIcon } from './Icons';
import { Input } from 'antd';
import { useTheme } from '../Themes/ThemeProvider';

const { TabPane } = Tabs;

function callback(key) {
    console.log(key);
}


const OptionHeader = ({ onTabChange, setDataLoading, dataLoading, getTrashData, status, setStatus, setSearchData, searchData }) => {
    const { themeColors } = useTheme();
    const [alignValue, setAlignValue] = React.useState('All');
    const [isborder, setIsBorder] = React.useState(false)

    const inputStyle = {
        fontSize: '14px',
        borderBottom: isborder ? `1px solid ${themeColors.borders.input}` : 'none', // Adjusted the border property
        borderRadius: 0
    };

    const onChange = (value) => {
        setDataLoading(true)
        if (value === 'trash') {
            getTrashData('trash')
        } else {
            onTabChange(value)
        }
        setStatus(value)
    }

    return (
        <>
            <div className='w-full flex flex-col items-center justify-between lg:flex-row py-0 lg:py-4'>
                <div className='w-full lg:w-1/2 px-4 py-2 flex lg:flex-row-reverse items-center justify-between gap-0 lg:gap-4 lg:justify-end lg:items-end'>
                    <div className='w-5/12'>
                        <Input
                            placeholder="Search here"
                            variant="borderless"
                            prefix={<SearchIcon />}
                            style={inputStyle}
                            value={searchData}
                            onChange={(e) => setSearchData(e?.target?.value)}
                            onFocus={() => setIsBorder(true)}
                            onBlur={() => setIsBorder(false)}
                        />
                    </div>
                    {/* <h1 className={`capitalize text-lg lg:text-2xl font-bold ${themeColors.headings.primary}`}>{alignValue}</h1> */}
                </div>
                <div className='w-full   flex items-center justify-center lg:justify-end '>
                    <Segmented
                        style={{
                            // width: '100%',
                            // padding:'5px 15px'
                            padding: 0
                        }}
                        defaultValue='all'
                        // trackBg='#aa3300'
                        // itemHoverBg='red'
                        // indicatorStyle={{ backgroundColor: 'black' }}
                        size='large'
                        onChange={(value) => onChange(value)}
                        options={[
                            {
                                label: (
                                    <div className='px-1 lg:px-3 py-1 flex items-center justify-center '>
                                        <h6>All</h6>
                                    </div>
                                ),
                                value: 'all'
                            },
                            {
                                label: (
                                    <div className='px-1 lg:px-3 py-1 flex items-center justify-center '>
                                        <h6>Draft</h6>
                                    </div>
                                ),
                                value: 'draft'
                            },
                            {
                                label: (
                                    <div className='px-1 lg:px-3 py-1 flex items-center justify-center '>
                                        <h6>In Process</h6>
                                    </div>
                                ),
                                value: 'send'
                            },
                            {
                                label: (
                                    <div className='px-1 lg:px-3 py-1 flex items-center justify-center '>
                                        <h6>Completed</h6>
                                    </div>
                                ),
                                value: 'completed'
                            },
                            {
                                label: (
                                    <div className='  lg:px-3 py-1 flex items-center justify-center '>
                                        {/* <h6>Completed</h6> */}
                                        <DeleteIcon color={themeColors.icons.sixth} />
                                    </div>
                                ),
                                value: 'trash'
                            },

                        ]}
                    />

                    {/* <ConfigProvider
                        theme={{
                            components: {
                                Segmented: {
                                    itemSelectedBg: '#424D51',
                                    itemSelectedColor: '#FFFFFF',
                                    itemHoverBg: 'transparent',
                                    itemActiveBg: 'transparent',
                                    itemActiveColor: 'yellow',
                                    // trackPadding: 10,
                                    // padding: 10
                                },
                            },
                            token: {
                                margin: 6,
                                fontSize: 12,
                            },
                        }}
                    >
                        <Segmented
                            // style={{ width: '100%' }}
                            defaultValue="All"
                            trackBg='#aa3300'
                            itemHoverBg='red'
                            indicatorStyle={{ backgroundColor: 'black' }}
                            size='large'
                            onChange={(value) => onChange(value)}
                            // options={['All', 'Drafts', 'In Process', 'Completed']}
                            options={[
                                {
                                    label: (
                                        <div className='px-2 py-1 flex items-center justify-center '>
                                            <h6>All</h6>
                                        </div>
                                    ),
                                    value: 'all'
                                },
                                {
                                    label: (
                                        <div className='px-2 py-1 flex items-center justify-center '>
                                            <h6>Draft</h6>
                                        </div>
                                    ),
                                    value: 'draft'
                                },
                                {
                                    label: (
                                        <div className='px-2 py-1 flex items-center justify-center '>
                                            <h6>In Process</h6>
                                        </div>
                                    ),
                                    value: 'send'
                                },
                                {
                                    label: (
                                        <div className='px-2 py-1 flex items-center justify-center '>
                                            <h6>Completed</h6>
                                        </div>
                                    ),
                                    value: 'completed'
                                },

                            ]}
                        />
                    </ConfigProvider> */}

                    <Button
                        icon={<DeleteIcon />}
                        type="link"
                        danger
                        style={{ display: 'none' }}
                        onClick={() => getTrashData('trash')}
                    >
                        {/* Danger Link */}
                    </Button>
                    {/* <DeleteIcon /> */}
                </div>
            </div>
        </>
    )
}

export default OptionHeader
