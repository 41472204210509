import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { Spin } from 'antd';

const Loader = () => {
    return (
        <>
            <div className='w-full h-screen flex items-center justify-center'>
                <LoadingOutlined style={{ fontSize: 24 }} spin />
            </div>
        </>
    )
}

export default Loader
